import React from 'react';

import { SectionBlog, SectionBlogOverview, SectionCategoryDomains, SectionCategoryHeader, SectionCTA, SectionDisclaimer, SectionDiscountDomains, SectionDiscountHeader, SectionDomain, SectionFooter, SectionForm, SectionFrontpageCategories, SectionFrontpageFeatured, SectionNew, SectionPremium, SectionPrivacy, SectionSearch} 

from '../components/Section';
import {ElementDebugResponsive } from '../components/Element';

// Props: translations, ip_address, debug
function PageBlog(props)
{
    return (
        <React.Fragment>
            <div className="body-content">
                <SectionBlog
                    debug={props.debug}
                    ip_address={props.ip_address}
                    translations={props.translations} />
            </div>

            <SectionFooter
                debug={props.debug}
                ip_address={props.ip_address}
                translations={props.translations} />

            { props.debug === true &&
                <ElementDebugResponsive />
            }
        </React.Fragment>
    );
}

// Props: translations, ip_address, debug
function PageBlogOverview(props)
{
    return (
        <React.Fragment>
            <div className="body-content">
                <SectionBlogOverview
                    debug={props.debug}
                    ip_address={props.ip_address}
                    translations={props.translations} />
            </div>
            
            <SectionFooter
                debug={props.debug}
                ip_address={props.ip_address}
                translations={props.translations} />

            { props.debug === true &&
                <ElementDebugResponsive />
            }
        </React.Fragment>
    );
}

// Props: translations, ip_address, debug
function PageCategory(props)
{
    return (
        <React.Fragment>
            <div className="body-content">
                <SectionCategoryHeader
                    debug={props.debug}
                    ip_address={props.ip_address}
                    translations={props.translations} />

                <SectionCategoryDomains
                    debug={props.debug}
                    ip_address={props.ip_address} 
                    translations={props.translations} />
            </div>

            <SectionFooter
                debug={props.debug}
                ip_address={props.ip_address}
                translations={props.translations} />

            { props.debug === true &&
                <ElementDebugResponsive />
            }
        </React.Fragment>
    );
}

// Props: translations, ip_address, debug
function PageContact(props)
{
    return (
        <React.Fragment>
            <div className="body-content">
                <SectionForm
                    debug={props.debug}
                    ip_address={props.ip_address}
                    translations={props.translations} />
            </div>

            <SectionFooter
                debug={props.debug}
                ip_address={props.ip_address}
                translations={props.translations} />

            { props.debug === true &&
                <ElementDebugResponsive />
            }
        </React.Fragment>
    );
}

// Props: translations, ip_address, debug
function PageDisclaimer(props)
{
    return (
        <React.Fragment>
            <div className="body-content">
                <SectionDisclaimer />
            </div>

            <SectionFooter
                debug={props.debug}
                ip_address={props.ip_address}
                translations={props.translations} />

            { props.debug === true &&
                <ElementDebugResponsive />
            }
        </React.Fragment>
    );
}

// Props: translations, ip_address, debug
function PageDiscount(props)
{
    return (
        <React.Fragment>
            <div className="body-content">
                <SectionDiscountHeader
                    debug={props.debug}
                    ip_address={props.ip_address} 
                    translations={props.translations} />

                <SectionDiscountDomains
                    debug={props.debug}
                    ip_address={props.ip_address}
                    translations={props.translations} />
            </div>

            <SectionFooter
                debug={props.debug}
                ip_address={props.ip_address}
                translations={props.translations} />

            { props.debug === true &&
                <ElementDebugResponsive />
            }
        </React.Fragment>  
    );
}

// Props: translations, ip_address, debug
function PageDomain(props)
{
    return (
        <React.Fragment>
            <div className="body-content">
                <SectionDomain
                    debug={props.debug}
                    ip_address={props.ip_address}
                    translations={props.translations} />
            </div>

            <SectionFooter
                debug={props.debug}
                ip_address={props.ip_address}
                translations={props.translations} />

            { props.debug === true &&
                <ElementDebugResponsive />
            }
        </React.Fragment>
    );
}

// Props: translations, ip_address, debug
function PageHomepage(props)
{
    return (
        <React.Fragment>
            <div className="body-content">
                <SectionSearch
                    debug={props.debug}
                    ip_address={props.ip_address}
                    translations={props.translations} />
                
                <SectionFrontpageFeatured
                    debug={props.debug}
                    ip_address={props.ip_address}
                    translations={props.translations} />

                <SectionFrontpageCategories
                    debug={props.debug}
                    ip_address={props.ip_address}
                    translations={props.translations} />

                <SectionCTA
                    debug={props.debug}
                    ip_address={props.ip_address}
                    translations={props.translations} />
            </div>

            <SectionFooter
                debug={props.debug}
                ip_address={props.ip_address}
                translations={props.translations} />

            { props.debug === true &&
                <ElementDebugResponsive />
            }
        </React.Fragment>
    );
}

// Props: translations, ip_address, debug
function PageNew(props)
{
    return (
        <React.Fragment>
            <div className="body-content">
                <SectionNew
                    debug={props.debug}
                    ip_address={props.ip_address} 
                    translations={props.translations} />
            </div>

            <SectionFooter
                debug={props.debug}
                ip_address={props.ip_address}
                translations={props.translations} />

            { props.debug === true &&
                <ElementDebugResponsive />
            }
        </React.Fragment>
    );
}

// Props: translations, ip_address, debug
function PagePremium(props)
{
    return (
        <React.Fragment>
            <div className="body-content">
                <SectionPremium
                    debug={props.debug}
                    ip_address={props.ip_address} 
                    translations={props.translations} />
            </div>

            <SectionFooter
                debug={props.debug}
                ip_address={props.ip_address}
                translations={props.translations} />

            { props.debug === true &&
                <ElementDebugResponsive />
            }
        </React.Fragment>
    );
}

// Props: translations, ip_address, debug
function PagePrivacy(props)
{
    return (
        <React.Fragment>
            <div className="body-content">
                <SectionPrivacy />
            </div>
            
            <SectionFooter
                debug={props.debug}
                ip_address={props.ip_address}
                translations={props.translations} />

            { props.debug === true &&
                <ElementDebugResponsive />
            }
        </React.Fragment>
    );
}

export { PageBlog, PageBlogOverview, PageCategory, PageContact, PageDisclaimer, PageDiscount, PageDomain, PageHomepage, PageNew, PagePremium, PagePrivacy };