
import React, { useState, useEffect } from 'react';
import { Link, useParams, useNavigate } from 'react-router-dom';
import Masonry from 'react-masonry-css'
import { Nav, Navbar, NavDropdown } from 'react-bootstrap';
import axios from 'axios';
import DOMPurify from 'dompurify';

import { ElementFooterLink, ElementHeader, ElementHeaderCentered, ElementHeaderCenteredSearch, ElementHeaderBlog, ElementLink, ElementLinkBlank, ElementPanelDomain, ElementTableHeaderColumn, ElementTableRow } from '../components/Element';

axios.defaults.xsrfHeaderName = "X-CSRFTOKEN";
axios.defaults.xsrfCookieName = "csrftoken";

// -----------------------------------------------------------------------

// Props: debug, ip address, translations
function SectionBlog(props)
{
    const {blog_slug} = useParams();

    const [blog_date, blog_set_date] = useState(null);
    const [blog_title, blog_set_title] = useState(null);
    const [blog_text, blog_set_text] = useState(null);
    const [blog_domains, blog_set_domains] = useState([]);

    const [profile_name, profile_set_name] = useState(null);
    const [profile_photo, profile_set_photo] = useState(null);
    const [profile_slogan, profile_set_slogan] = useState(null);
    const [profile_linkedin, profile_set_linkedin] = useState(null);
    const [profile_whatsapp, profile_set_whatsapp] = useState(null);

    const navigate = useNavigate();

    useEffect(() => {
        const get = async() => {
            try
            {
                let params = {
                    blog_slug: blog_slug
                };
                
                const response = await axios.get(props.ip_address+'/backend/domains/api/blog_info', {params});
                
                // <str> date (DD-MM-YYYY)
                // <str> title
                // <str> text
                // [<str> domain name, <bool> domain new, <bool> domain discount, <bool> domain premium, <int> domain price]

                // <str> profile name
                // <str> profile phoot
                // <str> profile slogan
                // <str> profile linkedin
                // <str> profile whatsapp
                
                blog_set_date(response.data[0]);
                blog_set_title(response.data[1]);
                blog_set_text(response.data[2]);
                blog_set_domains(response.data[3]);
                
                profile_set_name(response.data[4]);
                profile_set_photo(response.data[5]);
                profile_set_slogan(response.data[6]);
                profile_set_linkedin(response.data[7]);
                profile_set_whatsapp(response.data[8]);
                
                if (props.debug === true)
                    console.log(response.data);
            }

            catch (error)
            {
                navigate('/kennisbank');
            }
        }

        get();
    }, [props.debug, props.ip_address, blog_slug, navigate]);

    let blog_domains_panel_mobile = '';
    let blog_domains_panel_desktop = '';
    if (blog_domains.length !== 0)
    {
        let domains = [];
        for (let i = 0; i < parseInt(blog_domains.length); i++)
        {
            // Domain
            domains.push(
                <div key={i}>
                    <ElementPanelDomain
                        first_item={i===0}
                        name={blog_domains[i][0]}
                        mark_new={blog_domains[i][1]}
                        mark_discount={blog_domains[i][2]}
                        mark_premium={blog_domains[i][3]}
                        price={blog_domains[i][4]}
                        translations={props.translations} />
                </div>
            );
        }

        blog_domains_panel_mobile = (
            <div className="masonry-column-single">
                <h2 className="panel-title">{props.translations['blog_domainsfromarticle']}</h2>

                {domains}
            </div>
        );
        
        blog_domains_panel_desktop = (
            <div className="masonry-column-single-right">
                <h2 className="panel-title">{props.translations['blog_domainsfromarticle']}</h2>

                {domains}
            </div>
        );
    }

    return (
        <section className="panel-section-continuous">
            <div className="container-lg">
                <div className="row">
                    <div className="col-12 d-block d-lg-none">
                        <div className="masonry-column-single">
                            <ElementHeaderBlog
                                text={blog_title} />
                            <div className="blog-date"><i className="fa-regular fa-clock"></i> {blog_date}</div>
                            <div className="banner-contact-container-mobile">
                                <div className="banner-contact-profile-inner">
                                    { profile_photo !== null &&
                                        <div className="banner-contact-profile-picture" style={{backgroundImage: 'url(../image/photo/'+profile_photo+')'}}></div>
                                    }

                                    { profile_photo === null &&
                                        <div className="banner-contact-profile-picture" style={{backgroundImage: 'url(../image/photo/default.jpg)'}}></div>
                                    }
                                    
                                    <div>
                                        <div className="banner-contact-profile-intro">{props.translations['blog_offeredby']}</div>
                                        
                                        { profile_name !== null &&
                                            <div className="banner-contact-profile-name"><i className="fa-solid fa-star"></i> {profile_name}</div>
                                        }

                                        { profile_slogan !== null &&
                                            <div className="banner-contact-profile-quote">{profile_slogan}</div>
                                        }

                                        <div className="banner-contact-profile-buttons">
                                            { profile_linkedin !== null &&
                                                <a href={profile_linkedin} target="_blank" rel="noreferrer"><div className="banner-contact-profile-linkedin">{props.translations['domainform_linkedin_subscribe']}</div></a>
                                            }

                                            { profile_whatsapp !== null &&
                                                <a aria-label="Chat on WhatsApp" href={profile_whatsapp} target="_blank" rel="noreferrer"><img alt="Chat on WhatsApp" src="/image/icon_whatsapp.svg" height="32px"/></a>
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="blog-text" dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(blog_text)}}></div>
                        </div>
                        
                        {blog_domains_panel_mobile}
                    </div>

                    <div className="col-6 d-none d-lg-block">
                        <div className="banner-contact-column-left">
                            <ElementHeaderBlog
                                text={blog_title} />
                            <div className="blog-date"><i className="fa-regular fa-clock"></i> {blog_date}</div>
                            <div className="blog-text" dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(blog_text)}}></div>
                        </div>
                    </div>
                    <div className="col-6 d-none d-lg-block">
                        <div className="banner-contact-container-side">
                            <div className="banner-contact-profile-inner">
                                { profile_photo !== null &&
                                    <div className="banner-contact-profile-picture" style={{backgroundImage: 'url(../image/photo/'+profile_photo+')'}}></div>
                                }

                                { profile_photo === null &&
                                    <div className="banner-contact-profile-picture" style={{backgroundImage: 'url(../image/photo/default.jpg)'}}></div>
                                }

                                <div>
                                    <div className="banner-profile-intro">{props.translations['blog_offeredby']}</div>

                                    { profile_name != null &&
                                        <div className="blog-profile-name"><i className="fa-solid fa-star"></i> {profile_name}</div>
                                    }

                                    { profile_slogan !== null &&
                                        <div className="banner-contact-profile-quote">{profile_slogan}</div>
                                    }

                                    <div className="blog-profile-buttons">
                                        { profile_linkedin !== null &&
                                            <a href={profile_linkedin} target="_blank" rel="noreferrer"><div className="banner-contact-profile-linkedin">{props.translations['domainform_linkedin_subscribe']}</div></a>
                                        }

                                        { profile_whatsapp !== null &&
                                            <a aria-label="Chat on WhatsApp" href={profile_whatsapp} target="_blank" rel="noreferrer"><img alt="Chat on WhatsApp" src="/image/icon_whatsapp.svg" height="32px"/></a>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                        
                        {blog_domains_panel_desktop}
                    </div>
                </div>
            </div>
        </section>
    );
}

// -----------------------------------------------------------------------

// Props: debug, ip address, translations
function SectionBlogOverview(props)
{
    const [blog_category_data, blog_category_set_data] = useState([]);
    useEffect(() => {
        const get = async() => {
            try
            {
                const response = await axios.get(props.ip_address+'/backend/domains/api/blog_categories');

                // Build list
                let list = [];
                for (let i = 0; i < response.data.length; i++)
                {
                    list.push([
                        response.data[i][0], // <str> blogcategory name
                        response.data[i][1] // blogposts (<str> title, <str> slug)
                    ])
                }

                blog_category_set_data(list);

                if (props.debug === true)
                    console.log(response.data);
            }

            catch (error)
            {
                console.log(error);
            }
        }

        get();
    }, [props.debug, props.ip_address]);

    const breakpoints = {
        default: 2,
        768: 1
    };

    let blog_categories = [];
    if (blog_category_data.length > 0)
    {
        for (let i = 0; i < parseInt(blog_category_data.length); i++)
        {
            // Blogs
            let blogs = [];
            for (let j = 0; j < parseInt(blog_category_data[i][1].length); j++)
            {
                blogs.push(
                    <div key={j}>
                        <Link
                            to={'/kennisbank/'+blog_category_data[i][1][j][1]}>
                            <h2 className="panel-title-blog-link">{blog_category_data[i][1][j][0]}</h2>
                        </Link>
                    </div>
                );
            }

            // Blog categories
            blog_categories.push(
                <div key={i}>
                    <div className="row">
                        <div className="col">
                            <ElementHeaderCentered
                                text={blog_category_data[i][0]} />
                        </div>
                    </div>
                    <div className="row masonry-container">
                        <div className="col">
                            <Masonry
                                breakpointCols={breakpoints}
                                className="masonry"
                                columnClassName="masonry-column">
                                
                                {blogs}
                            </Masonry>
                        </div>
                    </div>
                </div>
            );
        }
    }

    return (
        <React.Fragment>
            <section className="banner-title-section">
                <div className="container-lg">
                    <div className="row">
                        <div className="d-block d-sm-none col-12">
                            <h1 className="banner-title-mobile"><span>{props.translations['navigation_blogoverview']}</span></h1>
                        </div>

                        <div className="d-none d-sm-block d-md-none col-12">
                            <h1 className="banner-title-tablet"><span>{props.translations['navigation_blogoverview']}</span></h1>
                        </div>

                        <div className="d-none d-md-block col-12">
                            <h1 className="banner-title-desktop"><span>{props.translations['navigation_blogoverview']}</span></h1>
                        </div>
                    </div>
                </div>
            </section>
            <section>
                <div className="container-lg">
                    {blog_categories}
                </div>
            </section>
        </React.Fragment>
    );
}

// -----------------------------------------------------------------------

// Props: debug, ip address, translations
function SectionCategoryDomains(props)
{
    // Category domains
    const {category_slug} = useParams();
    const [category_common_data, category_common_set_data] = useState([]);
    const [category_new_data, category_new_set_data] = useState([]);
    const [category_discount_data, category_discount_set_data] = useState([]);
    const [category_premium_data, category_premium_set_data] = useState([]);
    
    const navigate = useNavigate();
    
    useEffect(() => {
        const get = async() => {
            try
            {
                let params = {
                    category_slug: category_slug
                };
                
                const response = await axios.get(props.ip_address+'/backend/domains/api/category_domains', {params});

                // <str> domain name
                // <bool> domain new
                // <bool> discount
                // <bool> premium
                // <int> price
                
                // Build lists
                let list_common = [];
                let list_new = [];
                let list_discount = [];
                let list_premium = [];
                for (let i = 0; i < response.data.length; i++)
                {
                    // Add to new
                    if (response.data[i][1] === true)
                    {
                        list_new.push([
                            response.data[i][0],
                            response.data[i][1],
                            response.data[i][2],
                            response.data[i][3],
                            response.data[i][4] 
                        ]);
                    }

                    // Add to discount
                    if (response.data[i][2] === true)
                    {
                        list_discount.push([
                            response.data[i][0],
                            response.data[i][1],
                            response.data[i][2],
                            response.data[i][3],
                            response.data[i][4] 
                        ]);
                    }
                    
                    // Add to premium
                    if (response.data[i][3] === true)
                    {
                        list_premium.push([
                            response.data[i][0],
                            response.data[i][1],
                            response.data[i][2],
                            response.data[i][3],
                            response.data[i][4] 
                        ]);
                    }

                    // Add to common
                    if (response.data[i][1] === false && response.data[i][2] === false && response.data[i][3] === false)
                    {
                        list_common.push([
                            response.data[i][0],
                            response.data[i][1],
                            response.data[i][2],
                            response.data[i][3],
                            response.data[i][4] 
                        ]);
                    }
                }

                category_common_set_data(list_common);
                category_new_set_data(list_new);
                category_discount_set_data(list_discount);
                category_premium_set_data(list_premium);

                if (props.debug === true)
                    console.log(response.data);
            }
            
            catch (error)
            {
                navigate('/');
            }
        }

        get();
    }, [props.debug, props.ip_address, category_slug, navigate]);

    let category_common_panel = '';
    if (category_common_data.length > 0)
    {
        let domains = [];
        for (let i = 0; i < parseInt(category_common_data.length); i++)
        {
            // Domain
            domains.push(
                <div key={i}>
                    <ElementPanelDomain
                        first_item={i===0}
                        name={category_common_data[i][0]}
                        mark_new={category_common_data[i][1]}
                        mark_discount={category_common_data[i][2]}
                        mark_premium={category_common_data[i][3]}
                        price={category_common_data[i][4]}
                        translations={props.translations} />
                </div>
            );
        }

        if (domains.length > 0)
        {
            category_common_panel = (
                <React.Fragment>
                    <h2 className="panel-title">{props.translations['domain_names']}</h2>
                    {domains}
                </React.Fragment>
            );
        }
    }

    let category_new_panel = '';
    if (category_new_data.length > 0)
    {
        let domains = [];
        for (let i = 0; i < parseInt(category_new_data.length); i++)
        {
            // Domain
            domains.push(
                <div key={i}>
                    <ElementPanelDomain
                        first_item={i===0}
                        name={category_new_data[i][0]}
                        mark_new={category_new_data[i][1]}
                        mark_discount={category_new_data[i][2]}
                        mark_premium={category_new_data[i][3]}
                        price={category_new_data[i][4]}
                        translations={props.translations} />
                </div>
            );
        }

        if (domains.length > 0)
        {
            category_new_panel = (
                <React.Fragment>
                    <h2 className="panel-title">{props.translations['domain_new']}</h2>
                    {domains}
                </React.Fragment>
            );
        }
    }

    let category_discount_panel = '';
    if (category_discount_data.length > 0)
    {
        let domains = [];
        for (let i = 0; i < parseInt(category_discount_data.length); i++)
        {
            // Domain
            domains.push(
                <div key={i}>
                    <ElementPanelDomain
                        first_item={i===0}
                        name={category_discount_data[i][0]}
                        mark_new={category_discount_data[i][1]}
                        mark_discount={category_discount_data[i][2]}
                        mark_premium={category_discount_data[i][3]}
                        price={category_discount_data[i][4]}
                        translations={props.translations} />
                </div>
            );
        }

        if (domains.length > 0)
        {
            category_discount_panel = (
                <React.Fragment>
                    <h2 className="panel-title">{props.translations['domain_discounts']}</h2>
                    {domains}
                </React.Fragment>
            );
        }
    }

    let category_premium_panel = '';
    if (category_premium_data.length > 0)
    {
        let domains = [];
        for (let i = 0; i < parseInt(category_premium_data.length); i++)
        {
            // Domain
            domains.push(
                <div key={i}>
                    <ElementPanelDomain
                        first_item={i===0}
                        name={category_premium_data[i][0]}
                        mark_new={category_premium_data[i][1]}
                        mark_discount={category_premium_data[i][2]}
                        mark_premium={category_premium_data[i][3]}
                        price={category_premium_data[i][4]}
                        translations={props.translations} />
                </div>
            );
        }

        if (domains.length > 0)
        {
            category_premium_panel = (
                <React.Fragment>
                    <h2 className="panel-title">{props.translations['domain_premium']}</h2>
                    {domains}
                </React.Fragment>
            );
        }
    }

    return (
        <section className="panel-section-continuous">
            <div className="container-lg">
                <div className="d-block d-md-none">
                    <div className="row">
                        <div className="col-12">
                            { category_common_panel !== '' &&
                                <div className="masonry-column-single">
                                    {category_common_panel}
                                </div>
                            }

                            { category_premium_panel !== '' &&
                                <div className="masonry-column-single">
                                    {category_premium_panel}
                                </div>
                            }

                            { category_new_panel !== '' &&
                                <div className="masonry-column-single">
                                    {category_new_panel}
                                </div>
                            }

                            { category_discount_panel !== '' &&
                                <div className="masonry-column-single">
                                    {category_discount_panel}
                                </div>
                            }
                        </div>
                    </div>
                </div>
                <div className="d-none d-md-block">
                    <div className="row masonry-container">
                        <div className="col-6">
                            { category_common_panel !== '' &&
                                <div className="masonry-column-single-left">
                                    {category_common_panel}
                                </div>
                            }
                        </div>
                        <div className="col-6">
                            { category_premium_panel !== '' &&
                                <div className="masonry-column-single-right">
                                    {category_premium_panel}
                                </div>
                            }

                            { category_new_panel !== '' &&
                                <div className="masonry-column-single-right">
                                    {category_new_panel}
                                </div>
                            }

                            { category_discount_panel !== '' &&
                                <div className="masonry-column-single-right">
                                    {category_discount_panel}
                                </div>
                            }
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

// -----------------------------------------------------------------------

// Props: debug, ip address, translations
function SectionCategoryHeader(props)
{
    // Category name
    const {category_slug} = useParams();
    const [category_name_data, category_name_set_data] = useState('');
    const [category_count_data, category_count_set_data] = useState('');
    const [category_background_data, category_background_set_data] = useState('');
    useEffect(() => {
        const get = async() => {
            try
            {
                let params = {
                    category_slug: category_slug
                };

                const response = await axios.get(props.ip_address+'/backend/domains/api/category_info', {params});

                // <str> category name
                // <int> domains total
                // <str> background
                
                category_name_set_data(response.data[0]);
                category_count_set_data(response.data[1]);
                category_background_set_data(response.data[2]);

                if (props.debug === true)
                    console.log(response.data);
            }
            
            catch (error)
            {
                console.log(error);
            }
        }

        get();
    }, [props.debug, props.ip_address, category_slug]);

    // Background image
    let background = {};
    if (category_background_data !== '')
    {
        background = {
            backgroundImage: 'url(/image/category/'+category_background_data+')'
        }
    }

    return (
        <section className="banner-category-section" style={background}>
            <div className="banner-category-overlay">
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <h1 className="banner-category-title">{category_name_data}</h1>
                            <p className="banner-category-subtitle">{category_count_data+' '+props.translations['domain_available']}</p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

// -----------------------------------------------------------------------

// Props: translations
function SectionCTA(props)
{
    // CTA discount
    const [cta_discount_data, cta_discount_set_data] = useState(0);
    useEffect(() => {
        const get = async() => {
            try
            {
                const response = await axios.get(props.ip_address+'/backend/domains/api/cta_discount');
                // <int> total

                cta_discount_set_data(response.data);

                if (props.debug === true)
                    console.log(response.data);
            }
            
            catch (error)
            {
                console.log(error);
            }
        }

        get();
    }, [props.debug, props.ip_address]);

    return (
        <section className="cta-section">
            <div className="container-md">
                <div className="row">
                    <div className="col">
                        <ElementHeaderCentered
                            text={props.translations['navigation_cta']} />
                    </div>
                </div>
                <div className="row">
                    <div className="col">
                        <div className="d-block d-sm-none">
                            <Link
                                to={'/koopjes'}
                                className="cta-mobile">
                                <span className="cta-text-mobile">{props.translations['navigation_cta_discount']}<span className="cta-notification-mobile">{cta_discount_data}</span></span>
                            </Link>
                        </div>

                        <div className="d-none d-sm-block">
                            <Link
                                to={'/koopjes'}
                                className="cta-desktop">
                                <span className="cta-text-desktop">{props.translations['navigation_cta_discount']}<span className="cta-notification-desktop">{cta_discount_data}</span></span>
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

// -----------------------------------------------------------------------

// Props: none
function SectionDisclaimer(props)
{
    return (
        <section className="panel-section-single">
            <div className="container">
                <ElementHeader
                    text="Vrijwaring" />

                <p>Deze pagina is voor het laatst aangepast op 04-02-2023.</p>
                <p>Door deze website te bezoeken en/of de op of via deze website aangeboden informatie te gebruiken, verklaart u zich akkoord met de toepasselijkheid van deze disclaimer.</p>

                <b>Gebruik van de website vinduwdomeinnaam.nl</b>
                <p>De informatie op deze website is uitsluitend bedoeld als algemene informatie. Er kunnen geen rechten aan de informatie op deze website worden ontleend. Hoewel VindUwDomeinnaam.nl zorgvuldigheid in acht neemt bij het samenstellen en onderhouden van deze website en daarbij gebruik maakt van bronnen die betrouwbaar geacht worden, kunnen wij niet instaan voor de juistheid, volledigheid en actualiteit van de geboden informatie. VindUwDomeinnaam.nl garandeert niet dat de website foutloos of ononderbroken zal functioneren of vrij zijn van virussen. VindUwDomeinnaam.nl wijst iedere aansprakelijkheid ten aanzien van de juistheid, volledigheid, actualiteit van de geboden informatie en het (ongestoord) gebruik van deze website uitdrukkelijk van de hand.</p>

                <b>Informatie van derden, producten en diensten</b>
                <p>Op de website van VindUwDomeinnaam.nl staan links naar websites van derden, wij aanvaarden geen aansprakelijkheid en geen enkele verantwoordelijkheid voor de inhoud, het gebruik of de beschikbaarheid van websites van derden. Het gebruik van dergelijke links is voor eigen risico. De informatie op dergelijke websites is door VindUwDomeinnaam.nl niet nader beoordeeld op juistheid, redelijkheid, actualiteit of volledigheid.</p>

                <b>Informatie gebruiken</b>
                <p>Alle intellectuele eigendomsrechten en andere rechten voor met betrekking tot alle op of via deze website aangeboden informatie (waaronder alle teksten, grafisch materiaal en logo’s) berusten bij VindUwDomeinnaam.nl en Thissen AI. Het is niet toegestaan informatie op deze website over te kopiëren, te downloaden of op enigerlei wijze openbaar te maken, te verspreiden of te verveelvoudigen zonder voorafgaande schriftelijke toestemming van VindUwDomeinnaam.nl en Thissen AI. U mag informatie op deze website wel afdrukken en/of downloaden voor uw eigen persoonlijk gebruik.</p>

                <b>Wijzigingen</b>
                <p>Wij hebben het recht de aangeboden informatie, met inbegrip van deze disclaimer, te allen tijde te wijzigen zonder hiervan nadere aankondiging te doen. Het is raadzaam om regelmatig te controleren of de op of via deze website aangeboden informatie, met inbegrip van deze disclaimer, gewijzigd is.</p>

                <b>Toepasselijk recht</b>
                <p>Op deze website en de disclaimer is het Nederlands recht van toepassing. Alle geschillen uit hoofde van of in verband met deze disclaimer zullen bij uitsluiting worden voorgelegd aan de bevoegde rechter binnen het arrondissement van VindUwDomeinnaam.nl.</p>
                
                <ElementLink
                    to="/"
                    text="Naar de thuispagina" />
            </div>
        </section>
    );
}

// -----------------------------------------------------------------------

// Props: debug, ip address, translations
function SectionDiscountDomains(props)
{
    // Categories
    const [categories_data, categories_set_data] = useState([]);
    useEffect(() => {
        const get = async() => {
            try
            {
                const response = await axios.get(props.ip_address+'/backend/domains/api/discount_categories');

                // Build list
                let list = []
                for (let i = 0; i < response.data.length; i++)
                {
                    list.push([
                        response.data[i][0], // <int> category id
                        response.data[i][1], // <str> category name
                        response.data[i][2], // <str> category slug
                        response.data[i][3], // highlighted domains (<str> name, <bool> new, <bool> discount, <bool> premium)
                        response.data[i][4]  // <int> number of domains for this category
                    ]);
                }

                categories_set_data(list);

                if (props.debug === true)
                    console.log(list);
            }
            
            catch (error)
            {
                console.log(error);
            }
        }

        get();
    }, [props.debug, props.ip_address]);
    
    let category_panels = [];
    if (categories_data.length > 0)
    {
        for (let i = 0; i < parseInt(categories_data.length); i++)
        {
            let domains = [];
            for (let j = 0; j < parseInt(categories_data[i][3].length); j++)
            {
                // Domain
                domains.push(
                    <div key={j}>
                        <ElementPanelDomain
                            first_item={j===0}
                            name={categories_data[i][3][j][0]}
                            mark_new={categories_data[i][3][j][1]}
                            mark_discount={categories_data[i][3][j][2]}
                            mark_premium={categories_data[i][3][j][3]}
                            price={categories_data[i][3][j][4]}
                            translations={props.translations} />
                    </div>
                );
            }

            // Category panel
            category_panels.push(
                <div key={i}>
                    <Link
                        to={'/categorie/'+categories_data[i][2]}>
                        <h2 className="panel-title-link">{categories_data[i][1]}</h2>
                    </Link>

                    {domains}

                    <div className="panel-bottom">
                        { props.translations['domain_view_all'] !== undefined &&
                            <Link
                                to={'/categorie/'+categories_data[i][2]}>
                                <span className="panel-domain-link" dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(props.translations['domain_view_all'].replace('$total', categories_data[i][4])+' '+categories_data[i][1].toLowerCase())}}></span>
                            </Link>
                        }
                    </div>
                </div>
            );
        }
    }

    const breakpoints = {
        default: 2,
        768: 1
    };

    return (
        <React.Fragment>
            <section className="discount-section">
                <div className="container-lg">
                    <div className="row">
                        <div className="col">
                            <ElementHeaderCentered
                                text={props.translations['navigation_categories']} />
                        </div>
                    </div>
                    <div className="row masonry-container">
                        <div className="col">
                            <Masonry
                                breakpointCols={breakpoints}
                                className="masonry"
                                columnClassName="masonry-column">
                                
                                {category_panels}
                            </Masonry>
                        </div>
                    </div>
                </div>
            </section>
        </React.Fragment>
    );
}

// -----------------------------------------------------------------------

// Props: debug, ip address, translations
function SectionDiscountHeader(props)
{
    // CTA discount
    const [discount_data, discount_set_data] = useState(0);
    useEffect(() => {
        const get = async() => {
            try
            {
                const response = await axios.get(props.ip_address+'/backend/domains/api/discount_info');
                discount_set_data(response.data);

                if (props.debug === true)
                    console.log(response.data);
            }
            
            catch (error)
            {
                console.log(error);
            }
        }

        get();
    }, [props.debug, props.ip_address]);

    return (
        <section className="banner-title-section">
            <div className="container">
                <div className="row">
                    <div className="d-block d-sm-none col-12">
                        <h1 className="banner-title-mobile"><span>{props.translations['banner_discount_title']}<span className="banner-notification-mobile">{discount_data}</span></span></h1>
                        <p className="banner-subtitle-mobile">{props.translations['banner_discount_subtitle']}</p>
                    </div>

                    <div className="d-none d-sm-block d-md-none col-12">
                        <h1 className="banner-title-tablet"><span>{props.translations['banner_discount_title']}<span className="banner-notification-tablet">{discount_data}</span></span></h1>
                        <p className="banner-subtitle-mobile">{props.translations['banner_discount_subtitle']}</p>
                    </div>

                    <div className="d-none d-md-block col-12">
                        <h1 className="banner-title-desktop"><span>{props.translations['banner_discount_title']}<span className="banner-notification-desktop">{discount_data}</span></span></h1>
                        <p className="banner-subtitle-desktop">{props.translations['banner_discount_subtitle']}</p>
                    </div>
                </div>
            </div>
        </section>
    );
}

// -----------------------------------------------------------------------

// Props: debug, ip address, translations
function SectionDomain(props)
{
    const {domain_slug} = useParams();

    const [domain_new, domain_set_new] = useState(false);
    const [domain_discount, domain_set_discount] = useState(false);
    const [domain_premium, domain_set_premium] = useState(false);
    const [domain_price, domain_set_price] = useState(null);
    const [domain_blogs, domain_set_blogs] = useState([]);

    const [profile_name, profile_set_name] = useState(null);
    const [profile_photo, profile_set_photo] = useState(null);
    const [profile_slogan, profile_set_slogan] = useState(null);
    const [profile_linkedin, profile_set_linkedin] = useState(null);
    const [profile_whatsapp, profile_set_whatsapp] = useState(null);

    const navigate = useNavigate();

    useEffect(() => {
        const get = async() => {
            try
            {
                let params = {
                    domain_slug: domain_slug
                };

                const response = await axios.get(props.ip_address+'/backend/domains/api/domain_info', {params});

                domain_set_new(response.data[0]);
                domain_set_discount(response.data[1]);
                domain_set_premium(response.data[2]);
                domain_set_price(response.data[3]);
                domain_set_blogs(response.data[4]);
                
                profile_set_name(response.data[5]);
                profile_set_photo(response.data[6]);
                profile_set_slogan(response.data[7]);
                profile_set_linkedin(response.data[8]);
                profile_set_whatsapp(response.data[9]);

                if (props.debug === true)
                    console.log(response.data);
            }
            
            catch (error)
            {
                navigate('/');
            }
        }

        get();
    }, [props.debug, props.ip_address, domain_slug, navigate]);

    const [form_name, form_set_name] = useState('');
    const [form_phone, form_set_phone] = useState('');
    const [form_email, form_set_email] = useState('');
    const [form_description, form_set_description] = useState('');
    const [form_terms, form_set_terms] = useState(false);
    const [form_accept, form_set_accept] = useState(false);
    const [form_sent, form_set_sent] = useState(false);
    const [form_loading, form_set_loading] = useState(false);
    const [form_error, form_set_error] = useState(false);

    const handleSubmit = (event) => {
        form_set_loading(true);

        const post = async () => {
            try
            {
                let form_phone_corrected = form_phone;
                if (form_phone_corrected === '')
                    form_phone_corrected = '-';

                let form_description_corrected = form_description;
                if (form_description_corrected === '')
                    form_description_corrected = '-';

                const data = {
                    domain: domain_slug,
                    name: form_name,
                    email: form_email,
                    phone: form_phone_corrected,
                    description: form_description_corrected,
                    terms: form_terms,
                    accept: form_accept
                }

                await axios.post(props.ip_address+'/backend/domains/api/form_domain', data);

                form_set_sent(true);
                form_set_loading(false);
            }

            catch (error)
            {
                console.log(error);
                form_set_error(true);
                form_set_loading(false);
            }
        }

        post();

        event.preventDefault();
    }

    let offered;
    if (domain_price !== null)
    {
        offered = (
            <div className="banner-contact-column-offered">{props.translations['domainform_offeredfor']+' '}<strong>{domain_price+',- ex btw'}</strong>.</div>
        );
    }

    let checkmarks = (
        <div className="banner-contact-column-checkmarks">
            <div className="banner-contact-column-text-domainform"><i className="fa-solid fa-check banner-contact-column-checkmark"></i>{props.translations['domainform_check1']}</div>
            <div className="banner-contact-column-text-domainform"><i className="fa-solid fa-check banner-contact-column-checkmark"></i>{props.translations['domainform_check2']}</div>
            <div className="banner-contact-column-text-domainform"><i className="fa-solid fa-check banner-contact-column-checkmark"></i>{props.translations['domainform_check3']}</div>
        </div>
    );

    let form_terms_class = 'form-checkbox';
    if (form_terms === true)
        form_terms_class = 'form-checkbox-active';

    let form = '';
    if (form_error === false)
    {
        if (form_sent === false)
        {
            if (form_loading === false)
            {
                form = (
                    <form onSubmit={event => handleSubmit(event)}>
                        <input
                            className="form-singleline"
                            name="form_name"
                            type="text"
                            maxLength="128"
                            value={form_name}
                            autoComplete="off"
                            onChange={event => form_set_name(event.target.value)}
                            placeholder="Naam *"
                            required />

                        <input
                            className="form-singleline"
                            name="form_email"
                            type="text"
                            maxLength="128"
                            value={form_email}
                            autoComplete="off"
                            onChange={event => form_set_email(event.target.value)}
                            placeholder="E-mail *"
                            required />

                        <input
                            className="form-singleline"
                            name="form_phone"
                            type="text"
                            maxLength="128"
                            value={form_phone}
                            autoComplete="off"
                            onChange={event => form_set_phone(event.target.value)}
                            placeholder="Telefoon" />

                        <textarea
                            className="form-multiline"
                            name="form_description"
                            maxLength="4096"
                            value={form_description}
                            onChange={event => form_set_description(event.target.value)}
                            placeholder="Beschrijving" />

                        <div className="form-checkbox-container">
                            <label className={form_terms_class}>
                                <input
                                    name="form_terms"
                                    type="checkbox"
                                    checked={form_terms}
                                    onChange={event => form_set_terms(event.target.checked)}
                                    required />
                                <span className="form-text">Ja, ik ga akkoord met de <ElementLinkBlank to="/privacyverklaring" text="Privacyverklaring" />. *</span>
                            </label>
                        </div>

                        <input
                            className="form-accept"
                            name="form_accept"
                            type="checkbox"
                            checked={form_accept}
                            onChange={event => form_set_accept(event.target.checked)} />

                        <button onClick = {() => {}} className="form-send"><span>{props.translations['domainform_send']}</span></button>
                    </form>
                );
            }
        }
        else
        {
            form = (
                <div className="banner-contact-column-thankyou">
                    {props.translations['domainform_thankyou']}
                </div>
            );
        }
    }

    let loading = '';
    if (form_loading === true)
    {
        loading = (
            <div className="form-loading"></div>
        );
    }

    let error = '';
    if (form_error === true)
    {
        error = (
            <div className="form-error">{props.translations['contactform_error']}</div>
        );
    }

    let domain_blogs_panel_mobile = '';
    let domain_blogs_panel_desktop = '';
    if (domain_blogs.length !== 0)
    {
        let blogs = [];
        for (let i = 0; i < parseInt(domain_blogs.length); i++)
        {
            // Blog
            blogs.push(
                <div key={i}>
                    <Link
                        to={'/kennisbank/'+domain_blogs[i][1]}>
                        <h2 className="panel-title-blog-link">{domain_blogs[i][0]}</h2>
                    </Link>
                </div>
            )
        }
        
        domain_blogs_panel_mobile = (
            <div className="masonry-column-single">
                <h2 className="panel-title">{props.translations['navigation_blogoverview']}</h2>
                
                {blogs}
            </div>
        );

        domain_blogs_panel_desktop = (
            <div className="masonry-column-single-right">
                <h2 className="panel-title">{props.translations['navigation_blogoverview']}</h2>
                
                {blogs}
            </div>
        );
    }
    
    return (
        <section className="banner-contact-section">
            <div className="container-lg">
                <div className="row">
                    <div className="col-12 d-block d-md-none">
                        <div className="masonry-column-single">
                            <span className="banner-contact-column-text-domainform">{props.translations['domainform_intro']}</span>
                            <h1 className="banner-contact-column-title-xs d-block d-sm-none">{domain_slug}</h1>
                            <h1 className="banner-contact-column-title-sm d-none d-sm-block d-md-none">{domain_slug}</h1>

                            { domain_new === true &&
                                <span className="banner-contact-column-new">{props.translations['domain_new']}</span>
                            }

                            { domain_discount === true &&
                                <span className="banner-contact-column-discount">{props.translations['domain_discount']}</span>
                            }

                            { domain_premium === true &&
                                <span className="banner-contact-column-premium"><span className="banner-contact-column-premium-star"><i className="fa-solid fa-star"></i></span> {props.translations['domain_premium']}</span>
                            }

                            <div className="banner-contact-container-mobile">
                                <div className="banner-contact-profile-inner">
                                    { profile_photo !== null &&
                                        <div className="banner-contact-profile-picture" style={{backgroundImage: 'url(../image/photo/'+profile_photo+')'}}></div>
                                    }

                                    { profile_photo === null &&
                                        <div className="banner-contact-profile-picture" style={{backgroundImage: "url(../image/photo/default.jpg)"}}></div>
                                    }
                                    
                                    <div>
                                        <div className="banner-contact-profile-intro">{props.translations['domainform_offeredby']}</div>
                                        
                                        { profile_name !== null &&
                                            <div className="banner-contact-profile-name"><i className="fa-solid fa-star"></i> {profile_name}</div>
                                        }

                                        { profile_slogan !== null &&
                                            <div className="banner-contact-profile-quote">{profile_slogan}</div>
                                        }

                                        <div className="banner-contact-profile-buttons">
                                            { profile_linkedin !== null &&
                                                <a href={profile_linkedin} target="_blank" rel="noreferrer"><div className="banner-contact-profile-linkedin">{props.translations['domainform_linkedin_subscribe']}</div></a>
                                            }

                                            { profile_whatsapp !== null &&
                                                <a aria-label="Chat on WhatsApp" href={profile_whatsapp} target="_blank" rel="noreferrer"><img alt="Chat on WhatsApp" src="/image/icon_whatsapp.svg" height="32px"/></a>
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                            
                            {offered}
                            {checkmarks}
                            {loading}
                            {form}
                            {error}
                        </div>
                        
                        {domain_blogs_panel_mobile}
                    </div>

                    <div className="col-6 d-none d-md-block">
                        <div className="banner-contact-column-left">
                            <span className="banner-contact-column-text-domainform">{props.translations['domainform_intro']}</span>
                            <h1 className="banner-contact-column-title-md d-none d-md-block d-lg-none">{domain_slug}</h1>
                            <h1 className="banner-contact-column-title-lg d-none d-lg-block d-xl-none">{domain_slug}</h1>
                            <h1 className="banner-contact-column-title-xl d-none d-xl-block d-xxl-none">{domain_slug}</h1>
                            <h1 className="banner-contact-column-title-xxl d-none d-xxl-block">{domain_slug}</h1>

                            { domain_new === true &&
                                <span className="banner-contact-column-new">{props.translations['domain_new']}</span>
                            }

                            { domain_discount === true &&
                                <span className="banner-contact-column-discount">{props.translations['domain_discount']}</span>
                            }

                            { domain_premium === true &&
                                <span className="banner-contact-column-premium"><span className="banner-contact-column-premium-star"><i className="fa-solid fa-star"></i></span> {props.translations['domain_premium']}</span>
                            }
                            
                            {offered}
                            {checkmarks}
                            {loading}
                            {form}
                            {error}
                        </div>
                    </div>
                    
                    <div className="col-6 d-none d-md-block">
                        <div className="banner-contact-container-side">
                            <div className="banner-contact-profile-inner">
                                { profile_photo !== null &&
                                    <div className="banner-contact-profile-picture" style={{backgroundImage: 'url(../image/photo/'+profile_photo+')'}}></div>
                                }

                                { profile_photo === null &&
                                    <div className="banner-contact-profile-picture" style={{backgroundImage: "url(../image/photo/default.jpg)"}}></div>
                                }

                                <div>
                                    <div className="banner-contact-profile-intro">{props.translations['domainform_offeredby']}</div>
                                    
                                    { profile_name != null &&
                                        <div className="banner-contact-profile-name"><i className="fa-solid fa-star"></i> {profile_name}</div>
                                    }

                                    { profile_slogan !== null &&
                                        <div className="banner-contact-profile-quote">{profile_slogan}</div>
                                    }

                                    <div className="banner-contact-profile-buttons">
                                        { profile_linkedin !== null &&
                                            <a href={profile_linkedin} target="_blank" rel="noreferrer"><div className="banner-contact-profile-linkedin">{props.translations['domainform_linkedin_subscribe']}</div></a>
                                        }

                                        { profile_whatsapp !== null &&
                                            <a aria-label="Chat on WhatsApp" href={profile_whatsapp} target="_blank" rel="noreferrer"><img alt="Chat on WhatsApp" src="/image/icon_whatsapp.svg" height="32px"/></a>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>

                        {domain_blogs_panel_desktop}
                    </div>
                </div>
            </div>         
        </section>
    );
}

// -----------------------------------------------------------------------

// Props: translations
function SectionFooter(props)
{
    // Footer categories
    const [footer_categories_data, footer_categories_set_data] = useState([]);
    useEffect(() => {
        const get = async() => {
            try
            {
                const response = await axios.get(props.ip_address+'/backend/domains/api/footer_categories');

                footer_categories_set_data(response.data)

                if (props.debug === true)
                    console.log(response.data);
            }
            
            catch (error)
            {
                console.log(error);
            }
        }
        
        get();
    }, [props.debug, props.ip_address]);

    let categories = [[], [], [], []];
    if (footer_categories_data.length > 0)
    {
        let chunks = 4;
        let chunk_size = Math.ceil(footer_categories_data.length/chunks);

        for (let i = 0; i < chunks; i++)
        {
            for (let j = 0; (j < chunk_size) && (i*chunk_size+j < footer_categories_data.length); j++)
            {
                categories[i].push(
                    <div key={i*chunk_size+j}>
                        <ElementFooterLink
                            to={"/categorie/"+footer_categories_data[i*chunk_size+j][1]}
                            text={footer_categories_data[i*chunk_size+j][0]} />
                    </div>
                );
            }
        }
    }
    
    return (
        <section className="footer-section">
            <div className="container-md">
                <div className="row">
                    <div className="d-none d-lg-block col-3">
                        <div className="footer-column-spacing">{props.translations['navigation_categories']}</div>
                        {categories[0]}
                    </div>
                    <div className="d-none d-lg-block col-3">
                        <div className="footer-column-spacing"></div>
                        {categories[1]}
                    </div>
                    <div className="d-none d-lg-block col-3">
                        <div className="footer-column-spacing"></div>
                        {categories[2]}
                    </div>
                    <div className="d-none d-lg-block col-3">
                        <div className="footer-column-spacing"></div>
                        {categories[3]}
                    </div>

                    <div className="d-none d-sm-block d-lg-none col-6">
                        <div className="footer-column-spacing">{props.translations['navigation_categories']}</div>
                        {categories[0]}
                        {categories[1]}
                    </div>
                    <div className="d-none d-sm-block d-lg-none col-6">
                        <div className="footer-column-spacing"></div>
                        {categories[2]}
                        {categories[3]}
                    </div>

                    <div className="d-block d-sm-none col-12">
                        <div className="footer-column-spacing">{props.translations['navigation_categories']}</div>
                        {categories[0]}
                        {categories[1]}
                        {categories[2]}
                        {categories[3]}
                    </div>
                </div>
                <div className="row footer-row-spacing-48">
                    <div className="col">
                        <div className="footer-spacing">
                            <div>
                                <ElementFooterLink
                                    to="/"
                                    text={props.translations['navigation_search']} />

                                <ElementFooterLink
                                    to={'/nieuw'}
                                    text='Nieuw'/>

                                <ElementFooterLink
                                    to={'/premium'}
                                    text='Premium' />
                                
                                <ElementFooterLink
                                    to={'/koopjes'}
                                    text={props.translations['navigation_discount']} />

                                <ElementFooterLink
                                    to={'/kennisbank'}
                                    text={props.translations['navigation_blogoverview']} />

                                <ElementFooterLink
                                    to={'/contact'}
                                    text={props.translations['navigation_contact']} />

                                <ElementFooterLink
                                    to={'/vrijwaring'}
                                    text={props.translations['navigation_disclaimer']} />

                                <ElementFooterLink
                                    to={'/privacyverklaring'}
                                    text={props.translations['navigation_privacy']} />                                
                            </div>
                        </div>

                        <hr className="footer-line" />

                        <div className="footer-spacing">
                            <span className="footer-text">
                                Domeinen ©{new Date().getFullYear()} <a className="footer-link-alt" href="https://www.linkedin.com/in/romanojennekens/" target="_blank" rel="nofollow noreferrer">Romano Jennekens</a>. KVK 14083780. Alle genoemde prijzen zijn exclusief btw. Platform ©{new Date().getFullYear()} <a className="footer-link-alt" href="https://thissen.ai/nl-NL" target="_blank" rel="noreferrer">Thissen AI®</a>. Alle rechten voorbehouden. 
                            </span>
                        </div>
                    </div>
                </div>
                <div className="row footer-row-spacing-24">
                    <div className="col">
                        <div className="footer-spacing">
                            <div>
                                <span className="footer-text">
                                    Bezoek ook:
                                </span>
                                <span className="footer-link-external">
                                    <a href='https://www.domeinnaamblog.nl/' target="_blank" rel="follow noreferrer" className="footer-link">Domeinnaamblog.nl <i className="fa-solid fa-up-right-from-square footer-icon"></i></a>
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

// -----------------------------------------------------------------------

// Props: debug, ip address, translations
function SectionForm(props)
{
    const [profile_name, profile_set_name] = useState(null);
    const [profile_photo, profile_set_photo] = useState(null);
    const [profile_slogan, profile_set_slogan] = useState(null);
    const [profile_linkedin, profile_set_linkedin] = useState(null);
    const [profile_whatsapp, profile_set_whatsapp] = useState(null);

    const navigate = useNavigate();

    useEffect(() => {
        const get = async() => {
            try
            {
                const response = await axios.get(props.ip_address+'/backend/domains/api/contact_info');

                // <str> profile name
                // <str> profile photo
                // <str> profile slogan
                // <str> profile linkedin
                // <str> profile whatsapp

                profile_set_name(response.data[0]);
                profile_set_photo(response.data[1]);
                profile_set_slogan(response.data[2]);
                profile_set_linkedin(response.data[3]);
                profile_set_whatsapp(response.data[4]);

                if (props.debug === true)
                    console.log(response.data);
            }
            
            catch (error)
            {
                navigate('/');
            }
        }

        get();
    }, [props.debug, props.ip_address, navigate]);

    const [form_name, form_set_name] = useState('');
    const [form_phone, form_set_phone] = useState('');
    const [form_email, form_set_email] = useState('');
    const [form_dropdown, form_set_dropdown] = useState(props.translations['contactform_dropdown1']);
    const [form_description, form_set_description] = useState('');
    const [form_terms, form_set_terms] = useState(false);
    const [form_accept, form_set_accept] = useState(false);
    const [form_sent, form_set_sent] = useState(false);
    const [form_loading, form_set_loading] = useState(false);
    const [form_error, form_set_error] = useState(false);

    // Update default dropdown when translation has been loaded
    useEffect(() => {
        form_set_dropdown(props.translations['contactform_dropdown1']);
    }, [props.translations,]);

    const handleSubmit = (event) => {
        form_set_loading(true);

        const post = async () => {
            try
            {
                let form_phone_corrected = form_phone;
                if (form_phone_corrected === '')
                    form_phone_corrected = '-';

                let form_description_corrected = form_description;
                if (form_description_corrected === '')
                    form_description_corrected = '-';

                const data = {
                    name: form_name,
                    phone: form_phone_corrected,
                    email: form_email,
                    dropdown: form_dropdown,
                    description: form_description_corrected,
                    terms: form_terms,
                    accept: form_accept
                }
                
                await axios.post(props.ip_address+'/backend/domains/api/form_contact', data);

                form_set_sent(true);
                form_set_loading(false);
            }
            
            catch (error)
            {
                console.log(error);
                form_set_error(true);
                form_set_loading(false);
            }
        }

        post();

        event.preventDefault();
    }

    let form_terms_class = 'form-checkbox';
    if (form_terms === true)
        form_terms_class = 'form-checkbox-active';

    let form;
    if (form_error === false)
    {
        if (form_sent === false)
        {
            if (form_loading === false)
            {
                form = (
                    <form onSubmit={event => handleSubmit(event)}>
                        <input
                            className="form-singleline"
                            name="form_name"
                            type="text"
                            maxLength="128"
                            value={form_name}
                            autoComplete="off"
                            onChange={event => form_set_name(event.target.value)}
                            placeholder="Naam *"
                            required />

                        <input
                            className="form-singleline"
                            name="form_email"
                            type="text"
                            maxLength="128"
                            value={form_email}
                            autoComplete="off"
                            onChange={event => form_set_email(event.target.value)}
                            placeholder="E-mail *"
                            required />

                        <input
                            className="form-singleline"
                            name="form_phone"
                            type="text"
                            maxLength="128"
                            value={form_phone}
                            autoComplete="off"
                            onChange={event => form_set_phone(event.target.value)}
                            placeholder="Telefoon" />

                        <select
                            className="form-singleline-dropdown"
                            name="form_dropdown"
                            onChange={event => form_set_dropdown(event.target.value)} >
                            
                            <option value={props.translations['contactform_dropdown1']}>{props.translations['contactform_dropdown1']}</option>
                            <option value={props.translations['contactform_dropdown2']}>{props.translations['contactform_dropdown2']}</option>
                            <option value={props.translations['contactform_dropdown3']}>{props.translations['contactform_dropdown3']}</option>
                        </select>
                        
                        <textarea
                            className="form-multiline"
                            name="form_description"
                            maxLength="4096"
                            value={form_description}
                            onChange={event => form_set_description(event.target.value)}
                            placeholder="Beschrijving" />

                        <div className="form-checkbox-container">
                            <label className={form_terms_class}>
                                <input
                                    name="form_terms"
                                    type="checkbox"
                                    checked={form_terms}
                                    onChange={event => form_set_terms(event.target.checked)}
                                    required />
                                <span className="form-text">Ja, ik ga akkoord met de <ElementLinkBlank to="/privacyverklaring" text="Privacyverklaring" />. *</span>
                            </label>
                        </div>

                        <input
                            className="form-accept"
                            name="form_accept"
                            type="checkbox"
                            checked={form_accept}
                            onChange={event => form_set_accept(event.target.checked)} />

                        <button onClick = {() => {}} className="form-send"><span>{props.translations['contactform_send']}</span></button>
                    </form>
                );
            }
        }
        else
        {
            form = (
                <div className="banner-contact-column-thankyou">
                    {props.translations['contactform_thankyou']}
                </div>
            );
        }
    }

    let loading = '';
    if (form_loading === true)
    {
        loading = (
            <div className="form-loading"></div>
        );
    }

    let error = '';
    if (form_error === true)
    {
        error = (
            <div className="form-error">{props.translations['contactform_error']}</div>
        );
    }

    return (
        <section className="banner-contact-section">
            <div className="container-lg">
                <div className="row">
                    <div className="col-12 d-block d-md-none">
                        <div className="masonry-column-single">
                            <h1 className="banner-contact-column-title-xs d-block d-sm-none">{props.translations['contactform_title']}</h1>
                            <h1 className="banner-contact-column-title-sm d-none d-sm-block d-md-none">{props.translations['contactform_title']}</h1>
                            <span className="banner-contact-column-text-contactform">{props.translations['contactform_intro']}</span>

                            <div className="d-block d-md-none">
                                <div className="banner-contact-container-mobile">
                                    <div className="banner-contact-profile-inner">
                                        { profile_photo !== null &&
                                            <div className="banner-contact-profile-picture" style={{backgroundImage: 'url(../image/photo/'+profile_photo+')'}}></div>
                                        }

                                        { profile_photo === null &&
                                            <div className="banner-contact-profile-picture" style={{backgroundImage: 'url(../image/photo/default.jpg)'}}></div>
                                        }

                                        <div>
                                            <div className="banner-contact-profile-intro">{props.translations['domainform_offeredby']}</div>
                                        
                                            { profile_name != null &&
                                                <div className="banner-contact-profile-name"><i className="fa-solid fa-star"></i> {profile_name}</div>
                                            }

                                            { profile_slogan !== null &&
                                                <div className="banner-contact-profile-quote">{profile_slogan}</div>
                                            }

                                            <div className="banner-contact-profile-buttons">
                                                { profile_linkedin !== null &&
                                                    <a href={profile_linkedin} target="_blank" rel="noreferrer"><div className="banner-contact-profile-linkedin">{props.translations['domainform_linkedin_subscribe']}</div></a>
                                                }

                                                { profile_whatsapp !== null &&
                                                    <a aria-label="Chat on WhatsApp" href={profile_whatsapp} target="_blank" rel="noreferrer"><img alt="Chat on WhatsApp" src="/image/icon_whatsapp.svg" height="32px"/></a>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            
                            {loading}
                            {form}
                            {error}
                        </div>
                    </div>

                    <div className="col-6 d-none d-md-block">
                        <div className="banner-contact-column-left">
                            <h1 className="banner-contact-column-title-md d-none d-md-block d-lg-none">{props.translations['contactform_title']}</h1>
                            <h1 className="banner-contact-column-title-lg d-none d-lg-block d-xl-none">{props.translations['contactform_title']}</h1>
                            <h1 className="banner-contact-column-title-xl d-none d-xl-block d-xxl-none">{props.translations['contactform_title']}</h1>
                            <h1 className="banner-contact-column-title-xxl d-none d-xxl-block">{props.translations['contactform_title']}</h1>
                            <span className="banner-contact-column-text-contactform">{props.translations['contactform_intro']}</span>
                            
                            {loading}
                            {form}
                            {error}
                        </div>
                    </div>

                    <div className="col-6 d-none d-md-block">
                        <div className="banner-contact-container-side">
                            <div className="banner-contact-profile-inner">
                                { profile_photo !== null &&
                                    <div className="banner-contact-profile-picture" style={{backgroundImage: 'url(../image/photo/'+profile_photo+')'}}></div>
                                }

                                { profile_photo === null &&
                                    <div className="banner-contact-profile-picture" style={{backgroundImage: 'url(../image/photo/default.jpg)'}}></div>
                                }

                                <div>
                                    <div className="banner-contact-profile-intro">{props.translations['domainform_offeredby']}</div>
                                    
                                    { profile_name != null &&
                                        <div className="banner-contact-profile-name"><i className="fa-solid fa-star"></i> {profile_name}</div>
                                    }

                                    { profile_slogan !== null &&
                                        <div className="banner-contact-profile-quote">{profile_slogan}</div>
                                    }

                                    <div className="blog-profile-buttons">
                                        { profile_linkedin !== null &&
                                            <a href={profile_linkedin} target="_blank" rel="noreferrer"><div className="blog-profile-linkedin">{props.translations['domainform_linkedin_subscribe']}</div></a>
                                        }

                                        { profile_whatsapp !== null &&
                                            <a aria-label="Chat on WhatsApp" href={profile_whatsapp} target="_blank" rel="noreferrer"><img alt="Chat on WhatsApp" src="/image/icon_whatsapp.svg" height="32px"/></a>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>         
        </section>
    );
}

// -----------------------------------------------------------------------

// Props: debug, ip address, translations
function SectionFrontpageCategories(props)
{
    // Categories
    const [categories_data, categories_set_data] = useState([]);
    useEffect(() => {
        const get = async() => {
            try
            {
                const response = await axios.get(props.ip_address+'/backend/domains/api/featured_categories');

                // Build list
                let list = [];
                for (let i = 0; i < response.data.length; i++)
                {
                    list.push([
                        response.data[i][0], // <int> category id
                        response.data[i][1], // <str> category name
                        response.data[i][2], // <str> category slug
                        response.data[i][3], // highlighted domains (<str> name, <bool> new, <bool> discount, <bool> premium, <int> domain price)
                        response.data[i][4]  // <int> number of domains for this category
                    ]);
                }

                categories_set_data(list);

                if (props.debug === true)
                    console.log(list);
            }
            
            catch (error)
            {
                console.log(error);
            }
        }

        get();
    }, [props.debug, props.ip_address]);

    let category_panels = [];
    if (categories_data.length > 0)
    {
        for (let i = 0; i < parseInt(categories_data.length); i++)
        {
            let domains = [];
            for (let j = 0; j < parseInt(categories_data[i][3].length); j++)
            {
                // Domains
                domains.push(
                    <div key={j}>
                        <ElementPanelDomain
                            first_item={j===0}
                            name={categories_data[i][3][j][0]}
                            mark_new={categories_data[i][3][j][1]}
                            mark_discount={categories_data[i][3][j][2]}
                            mark_premium={categories_data[i][3][j][3]}
                            price={categories_data[i][3][j][4]}
                            translations={props.translations} />
                    </div>
                );
            }

            // Category panels
            category_panels.push(
                <div key={i}>
                    <Link
                        to={'/categorie/'+categories_data[i][2]}>
                        <h2 className="panel-title-link">{categories_data[i][1]}</h2>
                    </Link>
                    
                    {domains}
                    
                    <div className="panel-bottom">
                        { props.translations['domain_view_all'] !== undefined &&
                            <Link
                                to={'/categorie/'+categories_data[i][2]}>
                                <span className="panel-footer-link" dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(props.translations['domain_view_all'].replace('$total', categories_data[i][4])+' '+categories_data[i][1].toLowerCase())}}></span>
                            </Link>
                        }
                    </div>
                </div>
            );
        }
    }

    const breakpoints = {
        default: 2,
        768: 1
    };

    return (
        <section className="panel-section">
            <div className="container-lg">
                <div className="row">
                    <div className="col">
                        <ElementHeaderCentered
                            text={props.translations['navigation_categories']} />
                    </div>
                </div>
                <div className="row masonry-container">
                    <div className="col">
                        <Masonry
                            breakpointCols={breakpoints}
                            className="masonry"
                            columnClassName="masonry-column">
                            
                            {category_panels}
                        </Masonry>
                    </div>
                </div>
            </div>
        </section>
    )
}

// -----------------------------------------------------------------------

// Props: debug, ip address, translations
function SectionFrontpageFeatured(props)
{
    // New
    const [new_data, new_set_data] = useState([]);
    const [new_total, new_set_total] = useState(0);
    useEffect(() => {
        const get = async() => {
            try
            {
                const response = await axios.get(props.ip_address+'/backend/domains/api/featured_new');

                // Build list
                let list = [];
                for (let i = 0; i < response.data[0].length; i++)
                {
                    list.push([
                        response.data[0][i][0], // <str> domain name
                        response.data[0][i][1], // <str> domain new
                        response.data[0][i][2], // <str> domain discount
                        response.data[0][i][3], // <str> domain premium
                        response.data[0][i][4]  // <int> domain price
                    ]);
                } 

                new_set_data(list);
                new_set_total(response.data[1]);

                if (props.debug === true)
                {
                    console.log(list);
                    console.log(response.data[1]);
                }
            }

            catch (error)
            {
                console.log(error);
            }
        }

        get();
    }, [props.debug, props.ip_address]);

    let domains_new = props.translations['domain_new_none'];
    if (new_data.length > 0)
    {
        domains_new = [];
        for (let i = 0; i < parseInt(new_data.length); i++)
        {
            // Domain
            domains_new.push(
                <div key={i}>
                    <ElementPanelDomain
                        first_item={i===0}
                        name={new_data[i][0]}
                        mark_new={new_data[i][1]}
                        mark_discount={new_data[i][2]}
                        mark_premium={new_data[i][3]}
                        price={new_data[i][4]}
                        translations={props.translations} />
                </div>
            );
        }
    }

    // Premium
    const [premium_data, premium_set_data] = useState([]);
    const [premium_total, premium_set_total] = useState(0);
    useEffect(() => {
        const get = async() => {
            try
            {
                const response = await axios.get(props.ip_address+'/backend/domains/api/featured_premium');

                // Build list
                let list = [];
                for (let i = 0; i < response.data[0].length; i++)
                {
                    list.push([
                        response.data[0][i][0], // <str> domain name
                        response.data[0][i][1], // <str> domain new
                        response.data[0][i][2], // <str> domain discount
                        response.data[0][i][3], // <str> domain premium
                        response.data[0][i][4]  // <int> domain price
                    ]);
                } 

                premium_set_data(list);
                premium_set_total(response.data[1]);
                
                if (props.debug === true)
                {
                    console.log(list);
                    console.log(response.data[1]);
                }
            }

            catch (error)
            {
                console.log(error);
            }
        }

        get();
    }, [props.debug, props.ip_address]);

    let domains_premium = props.translations['domain_premium_none'];
    if (premium_data.length > 0)
    {
        domains_premium = [];
        for (let i = 0; i < parseInt(premium_data.length); i++)
        {
            // Domain
            domains_premium.push(
                <div key={i}>
                    <ElementPanelDomain
                        first_item={i===0}
                        name={premium_data[i][0]}
                        mark_new={premium_data[i][1]}
                        mark_discount={premium_data[i][2]}
                        mark_premium={premium_data[i][3]}
                        price={premium_data[i][4]}
                        translations={props.translations} />
                </div>
            );
        }
    }

    const breakpoints = {
        default: 2,
        768: 1
    };

    return (
        <section className="panel-section-continuous">
            <div className="container-lg">
                <div className="row">
                    <div className="col">
                        <ElementHeaderCentered
                            text={props.translations['navigation_featured']} />
                    </div>
                </div>
                <div className="row masonry-container">
                    <div className="col">
                        <Masonry
                            breakpointCols={breakpoints}
                            className="masonry"
                            columnClassName="masonry-column">
                            
                            <div>
                                <Link
                                    to={'/nieuw'}>
                                    <h2 className="panel-title-link">{props.translations['domain_new']}</h2>
                                </Link>
                                
                                {domains_new}

                                <div className="panel-bottom">
                                    { props.translations['domain_view_new'] !== undefined &&
                                        <Link
                                            to={'/nieuw'}>
                                            <span className="panel-footer-link" dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(props.translations['domain_view_new'].replace('$total', new_total))}}></span>
                                        </Link>
                                    }
                                </div>
                            </div>
                            
                            <div>
                                <Link
                                    to={'/premium'}>
                                    <h2 className="panel-title-link">{props.translations['domain_premium']}</h2>
                                </Link>

                                {domains_premium}

                                <div className="panel-bottom">
                                    { props.translations['domain_view_premium'] !== undefined &&
                                        <Link
                                            to={'/premium'}>
                                            <span className="panel-footer-link" dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(props.translations['domain_view_premium'].replace('$total', premium_total))}}></span>
                                        </Link>
                                    }
                                </div>
                            </div>
                        </Masonry>
                    </div>
                </div>
            </div>
        </section>
    );
}

// -----------------------------------------------------------------------

// Props: debug, ip address, translations
function SectionNavigation(props)
{
    // Expanded (used for dropdown mobile menu)
    const [expanded, setExpanded] = useState(false);

    // Dropdown (used for categories menu)
    const [dropdown, setDropdown] = useState(false);

    // Navigation categories
    const [navigation_categories_data, navigation_categories_set_data] = useState([]);
    useEffect(() => {
        const get = async() => {
            try
            {
                const response = await axios.get(props.ip_address+'/backend/domains/api/navigation_categories');

                navigation_categories_set_data(response.data)

                if (props.debug === true)
                    console.log(response.data);
            }
            
            catch (error)
            {
                console.log(error);
            }
        }
        
        get();
    }, [props.debug, props.ip_address]);
    
    let categories = [[], [], [], []];
    if (navigation_categories_data.length > 0)
    {
        let chunks = 4;
        let chunk_size = Math.ceil(navigation_categories_data.length/chunks);

        for (let i = 0; i < chunks; i++)
        {
            for (let j = 0; (j < chunk_size) && (i*chunk_size+j < navigation_categories_data.length); j++)
            {
                categories[i].push(
                    <div key={i*chunk_size+j}>
                        <Link to={'/categorie/'+navigation_categories_data[i*chunk_size+j][1]} className="dropdown-menu-link" onClick={() => {setExpanded(false); setDropdown(false);}}>{navigation_categories_data[i*chunk_size+j][0]}</Link>
                    </div>
                );
            }
        }
    }

    // CTA discount
    const [cta_discount_data, cta_discount_set_data] = useState(0);
    useEffect(() => {
        const get = async() => {
            try
            {
                const response = await axios.get(props.ip_address+'/backend/domains/api/cta_discount');
                cta_discount_set_data(response.data);

                if (props.debug === true)
                    console.log(response.data);
            }
            
            catch (error)
            {
                console.log(error);
            }
        }

        get();
    }, [props.debug, props.ip_address]);

    return (
        <React.Fragment>
            <section className="navbar-section fixed-top">
                <div className="container-lg">
                    <Navbar expanded={expanded} expand="lg" variant="dark" className="navbar-container">
                        <Link to="/" className="navbar-title" onClick={() => setExpanded(false)}>VindUwDomeinnaam.nl</Link>

                        <Navbar.Toggle aria-controls="navbarScroll" data-bs-target="$navbarScroll" onClick={() => setExpanded(expanded ? false : 'expanded')} />
                        <Navbar.Collapse id="navbarScroll" className="navbar-justify-right navbar-scroll">
                            <Nav>
                                <div className="navbar-listitem">
                                    <Link to="/" className="navbar-item" onClick={() => setExpanded(false)}>{props.translations['navigation_search']}</Link>
                                </div>
                                
                                <div className="navbar-listitem">
                                    <Link to="/nieuw" className="navbar-item" onClick={() => setExpanded(false)}>Nieuw</Link>
                                </div>

                                <div className="navbar-listitem">
                                    <Link to="/premium" className="navbar-item" onClick={() => setExpanded(false)}>Premium</Link>
                                </div>

                                <NavDropdown title={props.translations['navigation_categories']} show={dropdown} onClick={() => setDropdown(dropdown ? false : true)}>
                                    <div className="dropdown-menu-arrow-container">
                                        <div className="dropdown-menu-arrow"></div>
                                    </div>
                                    
                                    <div className="dropdown-menu-bubble">
                                        <div className="row">
                                            <div className="d-none d-lg-block col-3">
                                                {categories[0]}
                                            </div>
                                            <div className="d-none d-lg-block col-3">
                                                {categories[1]}
                                            </div>
                                            <div className="d-none d-lg-block col-3">
                                                {categories[2]}
                                            </div>
                                            <div className="d-none d-lg-block col-3">
                                                {categories[3]}
                                            </div>

                                            <div className="d-none d-sm-block d-lg-none col-6">
                                                {categories[0]}
                                                {categories[1]}
                                            </div>
                                            <div className="d-none d-sm-block d-lg-none col-6">
                                                {categories[2]}
                                                {categories[3]}
                                            </div>

                                            <div className="d-block d-sm-none col-12">
                                                {categories[0]}
                                                {categories[1]}
                                                {categories[2]}
                                                {categories[3]}
                                            </div>
                                        </div>
                                    </div>
                                </NavDropdown>

                                <div className="navbar-listitem">
                                    <Link to="/koopjes" className="navbar-item" onClick={() => setExpanded(false)}>{props.translations['navigation_discount']}<span className="navbar-item-notification">{cta_discount_data}</span></Link>
                                </div>

                                <div className="navbar-listitem">
                                    <Link to="/contact" className="navbar-item" onClick={() => setExpanded(false)}>Contact</Link>
                                </div>
                            </Nav>
                        </Navbar.Collapse>
                    </Navbar>
                </div>
            </section>
            <section className="navbar-spacing"></section>
        </React.Fragment>
    );
}

// -----------------------------------------------------------------------

// Props: debug, ip address, translations
function SectionNew(props)
{
    // New domains
    const [new_new_data, new_new_set_data] = useState([]);
    const [new_discount_data, new_discount_set_data] = useState([]);
    const [new_premium_data, new_premium_set_data] = useState([]);

    useEffect(() => {
        const get = async() => {
            try
            {
                const response = await axios.get(props.ip_address+'/backend/domains/api/new_domains');

                // Build lists
                let list_new = [];
                let list_discount = [];
                let list_premium = [];
                for (let i = 0; i < response.data.length; i++)
                {
                    // Add to new
                    if (response.data[i][1] === true)
                    {
                        list_new.push([
                            response.data[i][0], // <str> domain name
                            response.data[i][1], // <str> domain new
                            response.data[i][2], // <str> domain discount
                            response.data[i][3], // <str> domain premium
                            response.data[i][4]  // <int> domain price
                        ]);
                    }

                    // Add to discount
                    if (response.data[i][2] === true)
                    {
                        list_discount.push([
                            response.data[i][0], // <str> domain name
                            response.data[i][1], // <str> domain new
                            response.data[i][2], // <str> domain discount
                            response.data[i][3], // <str> domain premium
                            response.data[i][4]  // <int> domain price
                        ]);
                    }
                    
                    // Add to premium
                    if (response.data[i][3] === true)
                    {
                        list_premium.push([
                            response.data[i][0], // <str> domain name
                            response.data[i][1], // <str> domain new
                            response.data[i][2], // <str> domain discount
                            response.data[i][3], // <str> domain premium
                            response.data[i][4]  // <int> domain price
                        ]);
                    }
                }

                new_new_set_data(list_new);
                new_discount_set_data(list_discount);
                new_premium_set_data(list_premium);

                if (props.debug === true)
                    console.log(response.data);
            }   

            catch (error)
            {
                console.log(error);
            }
        }

        get();
    }, [props.debug, props.ip_address]);

    let new_new_panel = '';
    if (new_new_data.length > 0)
    {
        let domains = [];
        for (let i = 0; i < parseInt(new_new_data.length); i++)
        {
            // Domain
            domains.push(
                <div key={i}>
                    <ElementPanelDomain
                        first_item={i===0}
                        name={new_new_data[i][0]}
                        mark_new={new_new_data[i][1]}
                        mark_discount={new_new_data[i][2]}
                        mark_premium={new_new_data[i][3]}
                        price={new_new_data[i][4]}
                        translations={props.translations} />
                </div>
            );
        }

        if (domains.length > 0)
        {
            new_new_panel = (
                <React.Fragment>
                    <h2 className="panel-title">{props.translations['domain_new']}</h2>
                    {domains}
                </React.Fragment>
            );
        }
    }

    let new_discount_panel = '';
    if (new_discount_data.length > 0)
    {
        let domains = [];
        for (let i = 0; i < parseInt(new_discount_data.length); i++)
        {
            // Domain
            domains.push(
                <div key={i}>
                    <ElementPanelDomain
                        first_item={i===0}
                        name={new_discount_data[i][0]}
                        mark_new={new_discount_data[i][1]}
                        mark_discount={new_discount_data[i][2]}
                        mark_premium={new_discount_data[i][3]}
                        price={new_discount_data[i][4]}
                        translations={props.translations} />
                </div>
            );
        }

        if (domains.length > 0)
        {
            new_discount_panel = (
                <React.Fragment>
                    <h2 className="panel-title">{props.translations['domain_discounts']}</h2>
                    {domains}
                </React.Fragment>
            );
        }
    }

    let new_premium_panel = '';
    if (new_premium_data.length > 0)
    {
        let domains = [];
        for (let i = 0; i < parseInt(new_premium_data.length); i++)
        {
            // Domain
            domains.push(
                <div key={i}>
                    <ElementPanelDomain
                        first_item={i===0}
                        name={new_premium_data[i][0]}
                        mark_new={new_premium_data[i][1]}
                        mark_discount={new_premium_data[i][2]}
                        mark_premium={new_premium_data[i][3]}
                        price={new_premium_data[i][4]}
                        translations={props.translations} />
                </div>
            );
        }

        if (domains.length > 0)
        {
            new_premium_panel = (
                <React.Fragment>
                    <h2 className="panel-title">{props.translations['domain_premium']}</h2>
                    {domains}
                </React.Fragment>
            );
        }
    }
    
    return (
        <section className="panel-section-continuous">
            <div className="container-lg">
                <div className="row">
                    <div className="col">
                        <ElementHeaderCentered
                            text={props.translations['domain_new_page']} />
                    </div>
                </div>
                <div className="d-block d-md-none">
                    <div className="row">
                        <div className="col-12">
                            { new_new_panel !== '' &&
                                <div className="masonry-column-single">
                                    {new_new_panel}
                                </div>
                            }

                            { new_premium_panel !== '' &&
                                <div className="masonry-column-single">
                                    {new_premium_panel}
                                </div>
                            }

                            { new_discount_panel !== '' &&
                                <div className="masonry-column-single">
                                    {new_discount_panel}
                                </div>
                            }
                        </div>
                    </div>
                </div>
                <div className="d-none d-md-block">
                    <div className="row masonry-container">
                        <div className="col-6">
                            { new_new_panel !== '' &&
                                <div className="masonry-column-single-left">
                                    {new_new_panel}
                                </div>
                            }
                        </div>
                        <div className="col-6">
                            { new_premium_panel !== '' &&
                                <div className="masonry-column-single-right">
                                    {new_premium_panel}
                                </div>
                            }

                            { new_discount_panel !== '' &&
                                <div className="masonry-column-single-right">
                                    {new_discount_panel}
                                </div>
                            }
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

// -----------------------------------------------------------------------

// Props: debug, ip address, translations
function SectionPremium(props)
{
    // New domains
    const [premium_new_data, premium_new_set_data] = useState([]);
    const [premium_discount_data, premium_discount_set_data] = useState([]);
    const [premium_premium_data, premium_premium_set_data] = useState([]);

    useEffect(() => {
        const get = async() => {
            try
            {
                const response = await axios.get(props.ip_address+'/backend/domains/api/premium_domains');

                // Build lists
                let list_new = [];
                let list_discount = [];
                let list_premium = [];
                for (let i = 0; i < response.data.length; i++)
                {
                    // Add to new
                    if (response.data[i][1] === true)
                    {
                        list_new.push([
                            response.data[i][0], // <str> domain name
                            response.data[i][1], // <str> domain new
                            response.data[i][2], // <str> domain discount
                            response.data[i][3], // <str> domain premium
                            response.data[i][4]  // <int> domain price
                        ]);
                    }

                    // Add to discount
                    if (response.data[i][2] === true)
                    {
                        list_discount.push([
                            response.data[i][0], // <str> domain name
                            response.data[i][1], // <str> domain new
                            response.data[i][2], // <str> domain discount
                            response.data[i][3], // <str> domain premium
                            response.data[i][4]  // <int> domain price
                        ]);
                    }
                    
                    // Add to premium
                    if (response.data[i][3] === true)
                    {
                        list_premium.push([
                            response.data[i][0], // <str> domain name
                            response.data[i][1], // <str> domain new
                            response.data[i][2], // <str> domain discount
                            response.data[i][3], // <str> domain premium
                            response.data[i][4]  // <int> domain price
                        ]);
                    }
                }

                premium_new_set_data(list_new);
                premium_discount_set_data(list_discount);
                premium_premium_set_data(list_premium);

                if (props.debug === true)
                    console.log(response.data);
            }   

            catch (error)
            {
                console.log(error);
            }
        }

        get();
    }, [props.debug, props.ip_address]);
    
    let premium_new_panel = '';
    if (premium_new_data.length > 0)
    {
        let domains = [];
        for (let i = 0; i < parseInt(premium_new_data.length); i++)
        {
            // Domain
            domains.push(
                <div key={i}>
                    <ElementPanelDomain
                        first_item={i===0}
                        name={premium_new_data[i][0]}
                        mark_new={premium_new_data[i][1]}
                        mark_discount={premium_new_data[i][2]}
                        mark_premium={premium_new_data[i][3]}
                        price={premium_new_data[i][4]}
                        translations={props.translations} />
                </div>
            );
        }

        if (domains.length > 0)
        {
            premium_new_panel = (
                <React.Fragment>
                    <h2 className="panel-title">{props.translations['domain_new']}</h2>
                    {domains}
                </React.Fragment>
            );
        }
    }

    let premium_discount_panel = '';
    if (premium_discount_data.length > 0)
    {
        let domains = [];
        for (let i = 0; i < parseInt(premium_discount_data.length); i++)
        {
            // Domain
            domains.push(
                <div key={i}>
                    <ElementPanelDomain
                        first_item={i===0}
                        name={premium_discount_data[i][0]}
                        mark_new={premium_discount_data[i][1]}
                        mark_discount={premium_discount_data[i][2]}
                        mark_premium={premium_discount_data[i][3]}
                        price={premium_discount_data[i][4]}
                        translations={props.translations} />
                </div>
            );
        }

        if (domains.length > 0)
        {
            premium_discount_panel = (
                <React.Fragment>
                    <h2 className="panel-title">{props.translations['domain_discounts']}</h2>
                    {domains}
                </React.Fragment>
            );
        }
    }

    let premium_premium_panel = '';
    if (premium_premium_data.length > 0)
    {
        let domains = [];
        for (let i = 0; i < parseInt(premium_premium_data.length); i++)
        {
            // Domain
            domains.push(
                <div key={i}>
                    <ElementPanelDomain
                        first_item={i===0}
                        name={premium_premium_data[i][0]}
                        mark_new={premium_premium_data[i][1]}
                        mark_discount={premium_premium_data[i][2]}
                        mark_premium={premium_premium_data[i][3]}
                        price={premium_premium_data[i][4]}
                        translations={props.translations} />
                </div>
            );
        }

        if (domains.length > 0)
        {
            premium_premium_panel = (
                <React.Fragment>
                    <h2 className="panel-title">{props.translations['domain_premium']}</h2>
                    {domains}
                </React.Fragment>
            );
        }
    }

    return (
        <section className="panel-section-continuous">
            <div className="container-lg">
                <div className="row">
                    <div className="col">
                        <ElementHeaderCentered
                            text={props.translations['domain_premium_page']} />
                    </div>
                </div>
                <div className="d-block d-md-none">
                    <div className="row">
                        <div className="col-12">
                        {    premium_premium_panel !== '' &&
                                <div className="masonry-column-single">
                                    {premium_premium_panel}
                                </div>
                            }

                            { premium_new_panel !== '' &&
                                <div className="masonry-column-single">
                                    {premium_new_panel}
                                </div>
                            }

                            { premium_discount_panel !== '' &&
                                <div className="masonry-column-single">
                                    {premium_discount_panel}
                                </div>
                            }
                        </div>
                    </div>
                </div>
                <div className="d-none d-md-block">
                    <div className="row masonry-container">
                        <div className="col-6">
                            { premium_premium_panel !== '' &&
                                <div className="masonry-column-single-left">
                                    {premium_premium_panel}
                                </div>
                            }
                        </div>
                        <div className="col-6">
                            { premium_new_panel !== '' &&
                                <div className="masonry-column-single-right">
                                    {premium_new_panel}
                                </div>
                            }

                            { premium_discount_panel !== '' &&
                                <div className="masonry-column-single-right">
                                    {premium_discount_panel}
                                </div>
                            }
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

// -----------------------------------------------------------------------

// Props: none
function SectionPrivacy(props)
{
    return (
        <section className="panel-section-single">
            <div className="container">
                <ElementHeader
                    text="Privacyverklaring" />

                <p>Deze pagina is voor het laatst aangepast op 04-02-2023.</p>
                <p>Wij zijn ervan bewust dat u vertrouwen stelt in ons. Wij zien het dan ook als onze verantwoordelijkheid om uw privacy te beschermen. Op deze pagina laten we u weten welke gegevens we verzamelen als u onze website gebruikt, waarom we deze gegevens verzamelen en hoe we hiermee uw gebruikservaring verbeteren. Zo snapt u precies hoe wij werken.</p>
                <p>Dit privacybeleid is van toepassing op de diensten van VindUwDomeinnaam.nl. U dient zich ervan bewust te zijn dat VindUwDomeinnaam.nl niet verantwoordelijk is voor het privacybeleid van andere sites en bronnen. Door gebruik te maken van deze website geeft u aan het privacybeleid te accepteren. VindUwDomeinnaam.nl respecteert de privacy van alle gebruikers van haar site en zorgt ervoor dat de persoonlijke informatie die u ons verschaft vertrouwelijk wordt behandeld.</p>

                <b>Waarvoor worden uw persoonsgegevens gebruikt?</b>
                <p>VindUwDomeinnaam.nl verwerkt de persoonsgegevens van haar klanten. Deze persoonsgegevens worden gebruikt om u zo goed mogelijk in contact met werkgevers, partners en/of werknemers te brengen.</p>

                <b>Geautomatiseerde verwerking aanvraag</b>
                <p>Uw gegevens worden geautomatiseerd verwerkt als u een aanvraag indient. Dit gebeurt aan de hand van de gegevens die u op het (elektronische) contactformulier hebt ingevuld.</p>

                <b>Uitwisseling met derden</b>
                <p>Uw persoonsgegevens kunnen gedeeld worden met onze partners waardoor zij in de gelegenheid komen om contact met u op te nemen.</p>

                <b>Marketing</b>
                <p>VindUwDomeinnaam.nl kan uw persoonsgegevens gebruiken om u te informeren en interesseren voor haar diensten. Wij maken soms selecties van het klantenbestand, bijvoorbeeld om een dienst aan een bepaalde doelgroep aan te prijzen. Bij het maken van selecties wordt geen gebruik gemaakt van financiële gegevens.</p>

                <b>Analyse</b>
                <p>VindUwDomeinnaam.nl zal uw persoonsgegevens gebruiken voor analyse ten behoeve van marketingactiviteiten.</p>

                <b>Cookies</b>
                <p>Wij verzamelen gegevens voor onderzoek om zo een beter inzicht te krijgen in onze klanten, zodat wij onze diensten hierop kunnen afstemmen. Deze website maakt gebruik van “cookies” om de website te helpen analyseren hoe gebruikers de site gebruiken. De door het cookie gegenereerde informatie over uw gebruik van de website kan worden overgebracht naar eigen beveiligde servers van VindUwDomeinnaam.nl of die van een derde partij. Wij gebruiken deze informatie om bij te houden hoe u de website gebruikt, om rapporten over de website-activiteit op te stellen en andere diensten aan te bieden met betrekking tot uw website-activiteit en internetgebruik.</p>

                <b>Hoelang worden uw persoonsgegevens bewaard?</b>
                <p>VindUwDomeinnaam.nl bewaart uw persoonsgegevens zolang hij deze nodig heeft voor het doel waarvoor VindUwDomeinnaam.nl uw gegevens in eerste instantie heeft gekregen. Dit betekent dat de meeste gegevens 5 jaar worden bewaard (met ingang van het volgende jaar waarop de gegevens betrekking hebben).</p>

                <b>Wat zijn uw rechten?</b>
                <p>U heeft recht op inzage, rectificatie, gegevenswissing, beperking van het gebruik van uw persoonsgegevens, overdraagbaarheid van uw persoonsgegevens, bezwaar en op het intrekken van uw toestemming. Hieronder kunt u lezen wat deze rechten inhouden.</p>

                <b>Inzage</b>
                <p>U heeft recht op inzage in de persoonsgegevens die VindUwDomeinnaam.nl van u heeft en informatie waarvoor hij die gegevens gebruikt. VindUwDomeinnaam.nl bewaart de persoonsgegevens in een beschermde omgeving. U kunt een verzoek indienen om uw gegevens in te zien.</p>

                <b>Gegevensoverdraagbaarheid</b>
                <p>U heeft via VindUwDomeinnaam.nl het recht uw persoonsgegevens te krijgen in een gestructureerde, gangbare en leesbare vorm, als die persoonsgegevens door u of namens u aan VindUwDomeinnaam.nl zijn verstrekt.</p>

                <b>Rectificatie</b>
                <p>U heeft het recht op correctie van onjuiste persoonsgegevens die op u betrekking hebben. U heeft er recht op dat onvolledige persoonsgegevens volledig worden gemaakt, bijvoorbeeld door het verstrekken van een aanvullende verklaring. Vermeld in uw verzoek welke gegevens gecorrigeerd moeten worden en waarom.</p>

                <b>Gegevenswissing</b>
                <p>U kunt VindUwDomeinnaam.nl vragen uw persoonsgegevens te wissen als een van de volgende gevallen van toepassing is:</p>
                <ul>
                    <li>VindUwDomeinnaam.nl heeft uw persoonsgegevens niet meer nodig</li>
                    <li>VindUwDomeinnaam.nl gebruikt uw gegevens op grond van uw toestemming, maar u trekt deze toestemming in</li>
                    <li>VindUwDomeinnaam.nl mocht uw persoonsgegevens niet gebruiken</li>
                    <li>VindUwDomeinnaam.nl was op grond van de wet al verplicht uw gegevens te wissen</li>
                    <li>VindUwDomeinnaam.nl gebruikt uw gegevens voor social media</li>
                </ul>
                <p>Vermeld in uw verzoek welke gegevens u wilt laten wissen en waarom u vindt dat VindUwDomeinnaam.nl dit moet doen.</p>

                <b>Op welke manier kunt u uw rechten uitoefenen?</b>
                <p>Als u een beroep wilt doen op een van de rechten die hierboven genoemd worden, kunt u daarvoor een verzoek indienen bij VindUwDomeinnaam.nl. Dit kunt u per e-mail doen naar info@thissen.ai.</p>

                <p>Wij zullen u dan binnen 30 dagen laten weten wat wij met uw verzoek hebben gedaan. Als deze aanvraag erg complex is kan de termijn met 30 dagen verlengd worden.</p>

                <b>Hoe wordt uw informatie beveiligd?</b>
                <p>Uw informatie wordt op de VindUwDomeinnaam.nl server beveiligd. Enkel medewerkers van VindUwDomeinnaam.nl en mensen met een verwerkingsovereenkomst hebben toegang tot deze gegevens.</p>

                <b>Hoe kunt u in contact treden met VindUwDomeinnaam.nl?</b>
                <p>We vinden het vanzelfsprekend dat we u duidelijk informeren over uw privacy. Heeft u verder nog vragen over het privacystatement, bent u het oneens met hoe VindUwDomeinnaam.nl met uw gegevens omgaat of wilt u uw gegevens laten verwijderen uit ons systeem? Dan kunt u uw verzoek indienen per e-mail naar info@thissen.ai.</p>

                <p>Deze privacy statement kan wijzigen.</p>

                <p>De laatste versie vindt u altijd hier. Geleen, 4 februari 2023</p>

                <ElementLink
                    to="/"
                    text="Naar de thuispagina" />
            </div>
        </section>
    );
}

// -----------------------------------------------------------------------

// Props: debug, ip address, translations
function SectionSearch(props)
{
    const [search, search_set] = useState('');
    const [search_term, search_set_term] = useState('');
    const [search_complete, search_set_complete] = useState(false);
    const [search_error, search_set_error] = useState(false);
    const [search_domains_data, search_domains_set_data] = useState([]);

    const [animation_section_mobile, animation_set_section_mobile] = useState({});
    const [animation_section_desktop, animation_set_section_desktop] = useState({});
    
    const [animation_title_mobile, animation_set_title_mobile] = useState({});
    const [animation_title_desktop, animation_set_title_desktop] = useState({});
    
    const [animation_subtitle_mobile, animation_set_subtitle_mobile] = useState({});
    const [animation_subtitle_desktop, animation_set_subtitle_desktop] = useState({});
    
    const [animation_checks_mobile, animation_set_checks_mobile] = useState({});
    const [animation_checks_desktop, animation_set_checks_desktop] = useState({});
    
    const handleSubmit = (event) => {
        const get = async () => {
            try
            {
                // Initiate animations
                animation_set_section_mobile({
                    height: '220px',
                    backgroundPosition: 'right top 35%',
                });
                animation_set_section_desktop({
                    height: '260px',
                    backgroundPosition: 'right top 35%',
                });

                animation_set_title_mobile({
                    paddingTop: '110px',
                });
                animation_set_title_desktop({
                    paddingTop: '80px',
                    fontSize: '48px',
                    lineHeight: '58px',
                });

                animation_set_subtitle_mobile({
                    height: '0px',
                    marginBottom: '0px',
                    visibility: 'hidden',
                    opacity: 0,
                });
                animation_set_subtitle_desktop({
                    fontSize: '19px',
                    lineHeight: '22px',
                });

                animation_set_checks_mobile({
                    visibility: 'hidden',
                    opacity: 0,
                });
                animation_set_checks_desktop({
                    visibility: 'hidden',
                    opacity: 0,
                });
                
                // Get data
                let params = {
                    search: search
                };

                const response = await axios.get(props.ip_address+'/backend/domains/api/search', {params});

                // Process data
                search_set_term(search);
                search_set_complete(true);
                search_set_error(false);
                search_domains_set_data(response.data);

                if (props.debug === true)
                    console.log(response.data);
            }
            
            catch (error)
            {
                // Failsafe
                search_set_complete(true);
                search_set_error(true);
            }
        }

        get();
        
        event.preventDefault();
    }

    // Domains (mobile)
    const [domains, domains_set] = useState([]);

    // Table (desktop)
    const [table_sort_index, table_set_sort_index] = useState(0);
    const [table_sort_order, table_set_sort_order] = useState(0);

    const table_sort_function = (sort_id) => {
        if (sort_id !== table_sort_index)
        {
            // Select new sorting column, default ascending
            table_set_sort_index(sort_id);
            table_set_sort_order(0);
        }
        else
        {
            // Selecting the current sorting column; flip ascending
            if (table_sort_order === 0)
                table_set_sort_order(1);
            else
                table_set_sort_order(0);
        }
    }

    const [table_rows, table_set_rows] = useState([]);
    useEffect(() => {
        let rows = [];
        if (search_domains_data.length > 0)
        {
            let rows_sorted = search_domains_data;

            const table_sort_str = (array_index) => {
                if (table_sort_order === 0)
                    rows_sorted.sort(function(a, b) {
                        const name_a = a[array_index].toUpperCase();
                        const name_b = b[array_index].toUpperCase();
                        
                        if (name_a < name_b)
                            return -1;
                        
                        if (name_a > name_b)
                            return 1;
                    
                        return 0;
                    });
                else
                    rows_sorted.sort(function(a, b) {
                        const name_a = a[array_index].toUpperCase();
                        const name_b = b[array_index].toUpperCase();
                        
                        if (name_a > name_b)
                            return -1;
                        
                        if (name_a < name_b)
                            return 1;
                    
                        return 0;
                    });
            }

            const table_sort_int = (array_index) => {
                if (table_sort_order === 0)
                    rows_sorted.sort((a, b) => (a[array_index] < b[array_index]) ? 1 : (a[array_index] > b[array_index]) ? -1 : 0);
                else
                    rows_sorted.sort((a, b) => (a[array_index] > b[array_index]) ? 1 : (a[array_index] < b[array_index]) ? -1 : 0);
            }

            // Domain defaults (mobile)
            switch (table_sort_index)
            {
                case 1:     table_sort_str(0); break; // Domain name            
                case 2:     table_sort_int(1); break; // Domain new      
                case 3:     table_sort_int(2); break; // Domain discount
                case 4:     table_sort_int(3); break; // Domain premium
                default:    break;
            }

            let domains = [];
            for (let i = 0; i < rows_sorted.length; i++)
            {
                // Domain (mobile)
                domains.push(
                    <div key={i}>
                        <ElementPanelDomain
                            first_item={i===0}
                            name={rows_sorted[i][0]}
                            mark_new={rows_sorted[i][1]}
                            mark_discount={rows_sorted[i][2]}
                            mark_premium={rows_sorted[i][3]}
                            price={rows_sorted[i][4]}
                            translations={props.translations} />
                    </div>
                );

                // Table (desktop)
                rows.push(
                    <React.Fragment key={i}>
                        <ElementTableRow
                            name={rows_sorted[i][0]}
                            new={rows_sorted[i][1]}
                            discount={rows_sorted[i][2]}
                            premium={rows_sorted[i][3]}
                            price={rows_sorted[i][4]}
                            translations={props.translations} />
                    </React.Fragment>
                );
            }

            table_set_rows(rows);
            domains_set(domains);
        }
    }, [props.translations, search_domains_data, table_sort_index, table_sort_order]);

    return (
        <React.Fragment>
            <div className="d-block d-md-none">
                <section className="banner-search-section" style={animation_section_mobile}>
                    <div className="banner-search-overlay">
                        <div className="container">
                            <div className="row">
                                <div className="col-12">
                                    <h1 className="banner-search-title-mobile" style={animation_title_mobile}>{props.translations['banner_search_title']}</h1>
                                    <p className="banner-search-subtitle-mobile" style={animation_subtitle_mobile}>{props.translations['banner_search_subtitle']}</p>
                                </div>
                            </div>

                            <div className="row">
                                <div className="col-12">
                                    <form className="banner-search-search-container-mobile" onSubmit={event => handleSubmit(event)}>
                                        <input
                                            className="banner-search-search-input-mobile"
                                            name="search"
                                            type="text"
                                            value={search}
                                            autoComplete="off"
                                            onChange={event => search_set(event.target.value)}
                                            placeholder={props.translations['banner_search_placeholder']} />

                                        <button className="banner-search-search-button" onClick={handleSubmit}>
                                            <i className="fa-solid fa-magnifying-glass"></i>
                                        </button>
                                    </form>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-12">
                                    <div className="banner-search-checks" style={animation_checks_mobile}>
                                        <div className="banner-search-check-mobile"><i className="fa-solid fa-check banner-search-check-checkmark"></i>{props.translations['banner_search_check1']}</div>
                                        <div className="banner-search-check-mobile"><i className="fa-solid fa-check banner-search-check-checkmark"></i>{props.translations['banner_search_check2']}</div>
                                        <div className="banner-search-check-mobile"><i className="fa-solid fa-check banner-search-check-checkmark"></i>{props.translations['banner_search_check3']}</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>

            <div className="d-none d-md-block">
                <section className="banner-search-section" style={animation_section_desktop}>
                    <div className="banner-search-overlay">
                        <div className="container">
                            <div className="row">
                                <div className="col-12">
                                    <h1 className="banner-search-title-desktop" style={animation_title_desktop}>{props.translations['banner_search_title']}</h1>
                                    <p className="banner-search-subtitle-desktop" style={animation_subtitle_desktop}>{props.translations['banner_search_subtitle']}</p>
                                </div>
                            </div>

                            <div className="row">
                                <div className="col-12">
                                    <form className="banner-search-search-container-desktop" onSubmit={event => handleSubmit(event)}>
                                        <input
                                            className="banner-search-search-input-desktop"
                                            name="search"
                                            type="text"
                                            value={search}
                                            autoComplete="off"
                                            onChange={event => search_set(event.target.value)}
                                            placeholder={props.translations['banner_search_placeholder']} />

                                        <button className="banner-search-search-button" onClick={handleSubmit}>
                                            <i className="fa-solid fa-magnifying-glass"></i>
                                        </button>
                                    </form>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-12">
                                    <div className="banner-search-checks" style={animation_checks_desktop}>
                                        <div className="banner-search-check-desktop"><i className="fa-solid fa-check banner-search-check-checkmark"></i>{props.translations['banner_search_check1']}</div>
                                        <div className="banner-search-check-desktop"><i className="fa-solid fa-check banner-search-check-checkmark"></i>{props.translations['banner_search_check2']}</div>
                                        <div className="banner-search-check-desktop"><i className="fa-solid fa-check banner-search-check-checkmark"></i>{props.translations['banner_search_check3']}</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>

            { search_complete === true && search_error === true &&
                <section className="panel-section-continuous">
                    <div className="container">
                        <div className="row">
                            <div className="col">
                                <ElementHeaderCenteredSearch
                                    text={props.translations['banner_search_results']} 
                                    searchterm={search_term}/>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col">
                                <p className="banner-search-text">
                                    {props.translations['banner_search_error']}
                                </p>
                            </div>
                        </div>
                    </div>
                </section>
            }

            { search_complete === true && search_error === false && search_domains_data.length === 0 &&
                <section className="panel-section-continuous">
                    <div className="container">
                        <div className="row">
                            <div className="col">
                                <ElementHeaderCenteredSearch
                                    text={props.translations['banner_search_results']}
                                    searchterm={search_term} />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col">
                                <p className="banner-search-text">
                                    {props.translations['banner_search_noresults']}
                                </p>
                            </div>
                        </div>
                    </div>
                </section>
            }           

            { search_complete === true && search_error === false && search_domains_data.length > 0 &&
                <section className="panel-section-continuous">
                    <div className="container-lg">
                        <div className="row">
                            <div className="col">
                                <ElementHeaderCenteredSearch
                                    text={props.translations['banner_search_results']}
                                    searchterm={search_term} />
                            </div>
                        </div>
                        <div className="masonry-column-single">
                            <div className="row">
                                <div className="col">
                                    <div className="d-block d-md-none">
                                        {domains}
                                    </div>
                                    <div className="d-none d-md-block">
                                        <table className="table-section">
                                            <thead>
                                                <tr className="table-header-row">
                                                    <ElementTableHeaderColumn
                                                        text={props.translations['domain_name']}
                                                        table_sort_index={table_sort_index}
                                                        table_sort_order={table_sort_order}
                                                        table_sort_id={1}
                                                        table_sort_variabletype={0}
                                                        table_sort_function={table_sort_function} />
                                                    
                                                    <ElementTableHeaderColumn
                                                        text={props.translations['domain_new']}
                                                        table_sort_index={table_sort_index}
                                                        table_sort_order={table_sort_order}
                                                        table_sort_id={2}
                                                        table_sort_variabletype={1}
                                                        table_sort_function={table_sort_function} />

                                                    <ElementTableHeaderColumn
                                                        text={props.translations['domain_discount']}
                                                        table_sort_index={table_sort_index}
                                                        table_sort_order={table_sort_order}
                                                        table_sort_id={3} 
                                                        table_sort_variabletype={1}
                                                        table_sort_function={table_sort_function} />

                                                    <ElementTableHeaderColumn
                                                        text={props.translations['domain_premium']}
                                                        table_sort_index={table_sort_index}
                                                        table_sort_order={table_sort_order}
                                                        table_sort_id={4}
                                                        table_sort_variabletype={1}
                                                        table_sort_function={table_sort_function} />
                                                        
                                                    <td></td>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {table_rows}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            }   
        </React.Fragment>
    );
}

export { SectionBlog, SectionBlogOverview, SectionCategoryDomains, SectionCategoryHeader, SectionCTA, SectionDisclaimer, SectionDiscountDomains, SectionDiscountHeader, SectionDomain, SectionFooter, SectionForm, SectionFrontpageCategories, SectionFrontpageFeatured, SectionNavigation, SectionNew, SectionPremium, SectionPrivacy, SectionSearch };