import React from 'react';
import { Link } from 'react-router-dom';

// Props: none
function ElementDebugResponsive()
{
    return (
        <div className="debug-responsive-container">
            <div className="d-block d-sm-none">
                <div className="debug-responsive-block"></div>
                <div className="debug-responsive-none"></div>
                <div className="debug-responsive-none"></div>
                <div className="debug-responsive-none"></div>
                <div className="debug-responsive-none"></div>
                <div className="debug-responsive-none"></div>
                <span className="debug-responsive-text">xs</span>
                <span className="debug-responsive-text-link"><a href="http://localhost:8002/backend/domains/admin" target="_blank" rel="noopener noreferrer">backend</a></span>
            </div>
            <div className="d-none d-sm-block d-md-none">
                <div className="debug-responsive-block"></div>
                <div className="debug-responsive-block"></div>
                <div className="debug-responsive-none"></div>
                <div className="debug-responsive-none"></div>
                <div className="debug-responsive-none"></div>
                <div className="debug-responsive-none"></div>
                <span className="debug-responsive-text">sm</span>
                <span className="debug-responsive-text-link"><a href="http://localhost:8002/backend/domains/admin" target="_blank" rel="noopener noreferrer">backend</a></span>
            </div>
            <div className="d-none d-md-block d-lg-none">
                <div className="debug-responsive-block"></div>
                <div className="debug-responsive-block"></div>
                <div className="debug-responsive-block"></div>
                <div className="debug-responsive-none"></div>
                <div className="debug-responsive-none"></div>
                <div className="debug-responsive-none"></div>
                <span className="debug-responsive-text">md</span>
                <span className="debug-responsive-text-link"><a href="http://localhost:8002/backend/domains/admin" target="_blank" rel="noopener noreferrer">backend</a></span>
            </div>
            <div className="d-none d-lg-block d-xl-none">
                <div className="debug-responsive-block"></div>
                <div className="debug-responsive-block"></div>
                <div className="debug-responsive-block"></div>
                <div className="debug-responsive-block"></div>
                <div className="debug-responsive-none"></div>
                <div className="debug-responsive-none"></div>
                <span className="debug-responsive-text">lg</span>
                <span className="debug-responsive-text-link"><a href="http://localhost:8002/backend/domains/admin" target="_blank" rel="noopener noreferrer">backend</a></span>
            </div>
            <div className="d-none d-xl-block d-xxl-none">
                <div className="debug-responsive-block"></div>
                <div className="debug-responsive-block"></div>
                <div className="debug-responsive-block"></div>
                <div className="debug-responsive-block"></div>
                <div className="debug-responsive-block"></div>
                <div className="debug-responsive-none"></div>
                <span className="debug-responsive-text">xl</span>
                <span className="debug-responsive-text-link"><a href="http://localhost:8002/backend/domains/admin" target="_blank" rel="noopener noreferrer">backend</a></span>
            </div>
            <div className="d-none d-xxl-block">
                <div className="debug-responsive-block"></div>
                <div className="debug-responsive-block"></div>
                <div className="debug-responsive-block"></div>
                <div className="debug-responsive-block"></div>
                <div className="debug-responsive-block"></div>
                <div className="debug-responsive-block"></div>
                <span className="debug-responsive-text">xxl</span>
                <span className="debug-responsive-text-link"><a href="http://localhost:8002/backend/domains/admin" target="_blank" rel="noopener noreferrer">backend</a></span>
            </div>
        </div>
    );
}

// Props: to, text
function ElementFooterLink(props)
{
    return (
        <Link to={props.to} className="footer-link">{props.text}</Link>
    );
}

// Props: text
function ElementHeader(props)
{
    return (
        <React.Fragment>
            <div className="d-block d-md-none">
                <h2 className="panel-title-h2-small">{props.text}</h2>
            </div>
            <div className="d-none d-md-block">
                <h2 className="panel-title-h2">{props.text}</h2>
            </div>
        </React.Fragment>
    );
}

// Props: text
function ElementHeaderCentered(props)
{
    return (
        <React.Fragment>
            <div className="d-block d-md-none">
                <h2 className="panel-title-h2-small" style={{textAlign: "center"}}>{props.text}</h2>
            </div>
            <div className="d-none d-md-block">
                <h2 className="panel-title-h2" style={{textAlign: "center"}}>{props.text}</h2>
            </div>
        </React.Fragment>
    );
}

// Props: text
function ElementHeaderCenteredSearch(props)
{
    return (
        <React.Fragment>
            <div className="d-block d-md-none">
                <h2 style={{textAlign: "center"}}>
                    <span className="panel-title-h2-small">
                        {props.text}
                    </span> <span className="panel-title-h2-small-accent">
                        {props.searchterm}
                    </span>
                </h2>
            </div>
            <div className="d-none d-md-block">
                <h2 style={{textAlign: "center"}}>
                    <span className="panel-title-h2">
                        {props.text}
                    </span> <span className="panel-title-h2-accent">
                        {props.searchterm}
                    </span>
                </h2>
            </div>
        </React.Fragment>
    );
}

// Props: text
function ElementHeaderBlog(props)
{
    return (
        <React.Fragment>
            <div className="d-block d-md-none">
                <h1 className="blog-title-mobile">{props.text}</h1>
            </div>
            <div className="d-none d-md-block">
                <h1 className="blog-title-desktop">{props.text}</h1>
            </div>
        </React.Fragment>
    );
}

// Props: to, text
function ElementLink(props)
{
    return (
        <Link to={props.to} className="body-link">{props.text}</Link>
    );
}

// Props: to, text
function ElementLinkBlank(props)
{
    return (
        <a href={props.to} className="body-link" target="_blank" rel="noopener noreferrer">{props.text}</a>
    );
}

// Props: first item, name, new, discount, premium, translations
function ElementPanelDomain(props)
{
    let panel_class = 'panel-domain-container';
    if (props.first_item === true)
        panel_class = 'panel-domain-container-first';

    return (
        <a href={'/domein/'+props.name} className={panel_class}>
            <div>
                <span className="panel-domain-name">{props.name}</span>

                <div className="panel-domain-buttons">
                    { props.mark_new === true &&
                        <div className="panel-domain-new">{props.translations['domain_new']}</div>
                    }

                    { props.mark_discount === true &&
                        <div className="panel-domain-discount">{props.translations['domain_discount']}</div>
                    }

                    { props.mark_premium === true &&
                        <div className="panel-domain-premium"><span className="panel-domain-premium-star"><i className="fa-solid fa-star"></i></span> {props.translations['domain_premium']}</div>
                    }
                </div>
            </div>
                
            { props.price === null &&
                <React.Fragment>
                    <div className="d-block d-lg-none">
                        <span className="panel-domain-link"><i className="fa-solid fa-chevron-right"></i></span>
                    </div>
                    <div className="d-none d-lg-block">
                        <span className="panel-domain-link">{props.translations['domain_contact']}</span>
                    </div>
                </React.Fragment>
            }

            { props.price !== null &&
                <span className="panel-domain-link">{props.price+',-'}</span>
            }
        </a>
    );
}

// Props: text, table_sort_index, table_sort_id, table_sort_function
function ElementTableHeaderColumn(props)
{
    let text = <span className="table-header-column-text">{props.text}</span>;
    let sort = <span className="table-header-column-sort-both"></span>;
    if (props.table_sort_index !== 0)
    {
        if (props.table_sort_id !== props.table_sort_index)
        {
            // Sorting another column
            text = <span className="table-header-column-text-nosort">{props.text}</span>;
            sort = <span className="table-header-column-sort-none"></span>;
        }
        else
        {
            // This column is being sorted up
            if (props.table_sort_order === 0)
            {
                text = <span className="table-header-column-text">{props.text}</span>;
                sort = <span className="table-header-column-sort-up"></span>;
            }
            else
            {
                // This column is being sorted down
                text = <span className="table-header-column-text">{props.text}</span>;
                sort = <span className="table-header-column-sort-down"></span>;
            }
        }
    }

    // Default
    return (
        <td className="table-header-column" onClick={() => props.table_sort_function(props.table_sort_id)}>
            {text}
            {sort}
        </td>
    );
}

// Props: translations
function ElementTableRow(props)
{
    const select_row = (slug) => {
        window.open('/domein/'+slug, '_blank');
    }  

    return (
        <tr className="table-row" onClick={() => select_row(props.name)}>
            <td className="table-row">
                {props.name}
            </td>
            <td className="table-row">
                { props.new === true && 
                    <span className="table-row-new">{props.translations['domain_new']}</span>
                }
            </td>
            <td className="table-row">
                { props.discount === true && 
                    <span className="table-row-discount">{props.translations['domain_discount']}</span>
                }
            </td>
            <td className="table-row">
                { props.premium === true && 
                    <span className="table-row-premium"><span className="table-row-premium-star"><i className="fa-solid fa-star"></i></span> {props.translations['domain_premium']}</span>
                }
            </td>
            <td className="table-row">
                { props.price === null &&
                    <React.Fragment>
                        <div className="d-block d-lg-none">
                            <div className="table-row-link"><i className="fa-solid fa-chevron-right"></i></div>
                        </div>

                        <div className="d-none d-lg-block">
                            <div className="table-row-link">{props.translations['domain_contact']}</div>
                        </div>
                    </React.Fragment>
                }

                { props.price !== null &&
                    <div className="table-row-link">{props.price+',-'}</div>
                }                   
            </td>
        </tr>
    );
}

export { ElementDebugResponsive, ElementFooterLink, ElementHeader, ElementHeaderCentered, ElementHeaderCenteredSearch, ElementHeaderBlog, ElementLink, ElementLinkBlank, ElementPanelDomain, ElementTableHeaderColumn, ElementTableRow }